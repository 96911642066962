import { api } from './base';

const programApi = api.injectEndpoints({
    endpoints: (build) => ({
        getStaff: build.query({
            query: () => '/program/staff',
            providesTags: ['Staff'],
        }),
        getPatients: build.query({
            query: () => '/program/patients',
            providesTags: ['Patients'],
        }),
        getProgramGroups: build.query({
            query: (programId) => `/program/${programId}/groups`,
            providesTags: ['ProgramGroups'],
        }),
        updateProgramPatientGroup: build.mutation({
            query: ({ updateData }) => ({
                url: `/program/patient-group/${updateData.programPatientGroupId}`,
                method: 'PATCH',
                body: {
                    restrictionType: updateData.restrictionType,
                    goals: updateData.goals,
                    dietRestrictions: updateData.dietRestrictions,
                },
            }),
            invalidatesTags: ['ProgramGroups'],
        }),
        getProgramPatientGroupPatients: build.query({
            query: ({ programId, groupId }) => `/program/patient-group/${programId}/${groupId}/patients`,
            providesTags: ['ProgramPatientGroupPatients'],
        }),
        getPatientDetail: build.query({
            query: (program_patient_id) => `/program/patient-detail/${program_patient_id}`,
            providesTags: ['PatientDetail'],
        }),
        getPatientMealPlans: build.query({
            query: (program_patient_id) => `/program/patient-meal-plans/${program_patient_id}`,
            providesTags: ['PatientMealPlans'],
        }),
        getPatientFoodLog: build.query({
            query: (program_patient_id) => `/program/patient-food-log/${program_patient_id}`,
            providesTags: ['PatientFoodLog'],
        }),
        updatePatientDetail: build.mutation({
            query: (updateData) => ({
                url: `/program/patient-detail/${updateData.program_patient_id}`,
                method: 'PATCH',
                body: updateData,
            }),
            invalidatesTags: ['PatientDetail', 'ProgramGroups', 'ProgramPatientsDetail'],
        }),
        getProgramPatientsDetail: build.query({
            query: () => '/program/patients-detail',
            providesTags: ['ProgramPatientsDetail'],
        }),
        createPatientInvite: build.mutation({
            query: ({ patient_email, program_patient_group_id }) => ({
                url: '/program/invite',
                method: 'POST',
                body: {
                    patientEmail: patient_email,
                    programPatientGroupId: program_patient_group_id
                },
            }),
            invalidatesTags: ['ProgramGroups'],
        }),
        acceptInvite: build.mutation({
            query: (inviteId) => ({
                url: `/program/invite/${inviteId}`,
                method: 'GET',
            }),
        }),
        createProgramPatientGroup: build.mutation({
            query: (data) => ({
                url: '/program/patient-group',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['ProgramGroups'],
        }),
        addProgramUser: build.mutation({
            query: (newUserData) => ({
                url: '/program-user',
                method: 'POST',
                body: newUserData,
            }),
            invalidatesTags: ['ProgramUsers', 'Staff', 'Patients'], // replace 'ProgramUsers' with the actual tag you're using for program users
        }),
        addProgramStaff: build.mutation({
            query: (newStaffData) => ({
                url: '/program/program-staff',
                method: 'POST',
                body: newStaffData,
            }),
            invalidatesTags: ['ProgramUsers', 'Staff'], // replace with the actual tags you're using
        }),
    })
});

export const { useAddProgramStaffMutation, useAddProgramUserMutation, useCreateProgramPatientGroupMutation, useCreatePatientInviteMutation, useGetProgramPatientsDetailQuery, useUpdatePatientDetailMutation, useGetStaffQuery, useGetPatientFoodLogQuery, useAcceptInviteMutation, useGetPatientMealPlansQuery, useGetPatientDetailQuery, useGetPatientsQuery, useGetProgramGroupsQuery, useUpdateProgramPatientGroupMutation, useGetProgramPatientGroupPatientsQuery } = programApi;

export default programApi;