import { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { StyledButton, StyledInput } from '../StyledInputs';
import DeleteDialog from './DeleteDialog';
import { useGetUserQuery } from '../../utils/redux/api/userApi';
import { useDeleteUserMutation } from '../../utils/redux/api/userApi';

const WrapperDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: '1rem',
    alignItems: 'center',
}));



const RequestDelete = () => {
    const [openDelete, setOpenDelete] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const { data: user, isError, error } = useGetUserQuery();
    const [deleteUser, { error: deleteError, isSuccess: isDeleteSuccess }] = useDeleteUserMutation();

    const handleDeleteClick = () => {
        setOpenDelete(true);
    }

    const handleDialogClose = () => {
        setOpenDelete(false);
    }

    const submitDelete = async () => {
        try {
            await deleteUser({ email: user.email });
            if (isDeleteSuccess) {
                setDeleted(true);
            }
        } catch (err) {
            console.error(deleteError);
        }
    };

    if (deleted) {
        return (
            <WrapperDiv>
                <Typography variant='h3'>Account will be deleted within 24 hours</Typography>
            </WrapperDiv>
        )
    }

    if (user) {

        return (
            <WrapperDiv>
                <Typography variant='h2'>To delete your account, please click the button below.</Typography>
                <StyledButton onClick={handleDeleteClick}>Delete Account</StyledButton>
                <DeleteDialog open={openDelete} user={user} submitDelete={submitDelete} handleClose={handleDialogClose} />
            </WrapperDiv>
        )
    }

    else {
        return (
            <WrapperDiv>
                <Typography variant='h4'>Please login to use this feature</Typography>
            </WrapperDiv>
        )
    }


}

export default RequestDelete;