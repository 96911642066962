import { useState, useEffect } from 'react';
import { Autocomplete, Typography, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import IngredientRow from './IngredientRow';
import { StyledButton } from './StyledInputs';
import { cuisines, allergies } from '../utils/listData';

const WrapperDiv = styled('div')(({ theme }) => ({
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',

    overflow: 'hidden',
    flex: '1 1 0',

}));

const ContentDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    minHeight: 'fit-content',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
    },
    border: '0.125rem solid #000000',
    borderRadius: '1rem',
}));

const InputDiv = styled('div')(({ theme }) => ({
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    scrollbarColor: '#e9c46a rgba(0,0,0,0.4)',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 0.37rem rgba(0,0,0,0.4)',
        border: '1px solid #000',
        borderRadius: '0.28rem',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#e9c46a',
        borderRadius: '0.28rem',
    },
}));


const IngredientDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    paddingRight: '1rem',
}));

const DietaryDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: '1rem',
    '& >*': {
        marginBottom: '1rem',
    }
}));

const ButtonDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'end',
    paddingRight: '1rem',
    marginBottom: '1rem',
    flex: '0 1 0'
}));

const IngredientsPanel = ({ handleSubmit, user }) => {
    const [ingredients, setIngredients] = useState([{ id: 0, value: '' }]);
    const [dietaryOption, setDietaryOption] = useState('');
    const [cuisineOption, setCuisineOption] = useState('');
    const [allergiesOption, setAllergiesOption] = useState([]);
    const [message, setMessage] = useState(null);


    const dietaryOptions = [
        {
            label: 'Low-Carb',
            value: 'low carb',
        },
        {
            label: 'Vegan',
            value: 'vegan',
        },
        {
            label: 'Bariatric',
            value: 'bariatric',
        },
        {
            label: 'Gluten-Free',
            value: 'gluten free',
        },
    ];

    const submitClicked = () => {
        const ingredientList = ingredients.map(i => {
            if (i.value) {
                return i.value
            }
            return null;
        });
        const filterEmpty = ingredientList.filter(Boolean)
        if (!filterEmpty.length) {
            setMessage('Please enter at least 1 ingredient.')
        }
        else {
            const dietary = dietaryOption ? dietaryOption.value : '';
            const allergens = allergiesOption ? allergiesOption.map(o => o.label) : '';
            handleSubmit(ingredientList, dietary, cuisineOption ?? '', allergens);
        }
    };

    const addRow = () => {
        const newId = ingredients.slice(-1)[0].id + 1;
        const newArr = ingredients.slice();
        newArr.push({ id: newId, value: '' });

        setIngredients(newArr);
    };

    const removeRow = (id) => {
        if (ingredients.length > 1) {
            const rows = ingredients.filter((ingredient) => ingredient.id !== id);
            setIngredients(rows);
        }
        else {
            setIngredients([{ id: 0, value: '' }]);
        }

    };

    const setIngredientValue = (ingredient) => {
        if (message) {
            setMessage(null);
        }
        const currentIndex = ingredients.findIndex((i) => i.id === ingredient.id);
        const updatedIngredient = { id: ingredient.id, value: ingredient.value };
        const newIngredients = [
            ...ingredients.slice(0, currentIndex),
            updatedIngredient,
            ...ingredients.slice(currentIndex + 1)
        ];
        setIngredients(newIngredients);
    };

    const DietarySelect = () => {
        return (
            <Autocomplete
                fullWidth
                value={dietaryOption}
                options={dietaryOptions}
                onChange={(e, newValue) => {
                    setDietaryOption(newValue);
                }}
                // getOptionLabel={(option) => (option.title ? option.title : '')}
                renderInput={(params) => <TextField {...params} label='Dietary Restriction (Optional)' variant='standard' InputLabelProps={{
                    shrink: true,
                }} />}
            />
        )
    }

    const CuisineSelect = () => {
        const cuisineOptions = cuisines.map((c, index) => {
            return { key: index, label: c }
        });
        return (
            <Autocomplete
                fullWidth
                value={cuisineOption}
                options={cuisineOptions}
                onChange={(e, newValue) => {
                    setCuisineOption(newValue ? newValue.label : '');
                }}
                // getOptionLabel={(option) => (option.title ? option.title : '')}
                renderInput={(params) => <TextField {...params} label='Cuisine (Optional)' variant='standard' InputLabelProps={{
                    shrink: true,
                }} />}
            />
        )
    }

    const AllergySelect = () => {
        const allergiesOptions = allergies.map((c, index) => {
            return { key: index, label: c }
        });
        return (
            <Autocomplete
                fullWidth
                multiple
                value={allergiesOption}
                options={allergiesOptions}
                onChange={(e, values) => {
                    setAllergiesOption(values);
                }}
                // getOptionLabel={(option) => (option.title ? option.title : '')}
                renderInput={(params) => <TextField {...params} label='Allergies (Optional)' variant='standard' InputLabelProps={{
                    shrink: true,
                }} />}
            />
        )
    }

    return (
        <WrapperDiv>
            <ContentDiv className='content-div'>
                <InputDiv className='input-div'>
                    {message &&
                        <Typography variant='subtitle1' mb='1rem'><strong>{message}</strong></Typography>}
                    {ingredients.map((ingredient, index) => {
                        return (
                            <IngredientDiv key={index}>
                                <IngredientRow id={ingredient.id} value={ingredient.value} setValue={setIngredientValue} removeRow={removeRow} />
                            </IngredientDiv>
                        )
                    })}
                </InputDiv>
                <ButtonDiv className='button-div'>
                    <StyledButton onClick={addRow} variant='text' sx={{ fontSize: '0.75rem', pr: 0 }}>Add Ingredient</StyledButton>

                    <DietaryDiv>
                        <DietarySelect />
                        <AllergySelect />
                        <CuisineSelect />
                    </DietaryDiv>

                    <StyledButton onClick={submitClicked} variant='contained' disabled={!user} title={!user ? 'Login to submit' : ''}>Submit</StyledButton>
                </ButtonDiv>
            </ContentDiv>
        </WrapperDiv>
    )
};

export default IngredientsPanel;