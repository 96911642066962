import { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { StyledInput } from './StyledInputs';



const WrapperDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '1rem',
}));

const InputDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
}));


const ButtonDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
}));


const IngredientRow = ({ id, value, setValue, removeRow, addRow = null }) => {
    const handleRemove = () => {
        removeRow(id);
    }

    const handleSetValue = (newValue) => {
        const ingredient = {
            id,
            value: newValue
        };
        setValue(ingredient);
    };

    return (
        <WrapperDiv>
            <InputDiv>
                <StyledInput fullWidth placeholder='Enter Ingredient' value={value} onChange={(e) => handleSetValue(e.target.value)} />
            </InputDiv>
            <ButtonDiv>
                <IconButton onClick={handleRemove}>
                    <RemoveCircleOutlineOutlinedIcon />
                </IconButton>
                {addRow &&
                    <IconButton onClick={addRow}>
                        <AddCircleOutlineOutlinedIcon />
                    </IconButton>}
            </ButtonDiv>
        </WrapperDiv>
    )
};

export default IngredientRow;