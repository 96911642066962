import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const EditDietRestrictions = ({ dietRestrictions, onSave, open, handleClose }) => {
    const [restrictions, setRestrictions] = useState(dietRestrictions);

    const handleSave = () => {
        onSave(restrictions);
        handleClose();
    };

    const handleRestrictionChange = (event, index) => {
        const newRestrictions = [...restrictions];
        newRestrictions[index] = event.target.value;
        setRestrictions(newRestrictions);
    };

    const handleAddRestriction = () => {
        setRestrictions([...restrictions, ""]);
    };

    const handleRemoveRestriction = (index) => {
        setRestrictions(restrictions.filter((_, i) => i !== index));
    };

    return (

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Diet Restrictions</DialogTitle>
            <DialogContent>
                {restrictions.map((restriction, index) => (
                    <div key={index}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label={`Diet Restriction ${index + 1}`}
                            value={restriction}
                            onChange={(event) => handleRestrictionChange(event, index)}
                            fullWidth
                        />
                        <Button onClick={() => handleRemoveRestriction(index)}>
                            Remove
                        </Button>
                    </div>
                ))}
                <Button onClick={handleAddRestriction}>
                    Add Diet Restriction
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDietRestrictions;