import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { StyledButton, StyledInput } from '../StyledInputs';

const WrapperDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '20rem',
    height: '100%',
    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const ContentDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}));

const TextDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    marginBottom: '1rem',
    '& form': {
        display: 'flex',
        flexDirection: 'column',
        '& >*:not(:last-child)': {
            marginBottom: '1rem',
        }
    },
}));

const ActionDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
}));



const DeleteDialog = ({ open, user, submitDelete, handleClose }) => {
    const [deleteConfirm, setDeleteConfirm] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);

    const handleChange = (e) => {
        const { value } = e.target;
        setDeleteConfirm(value);
        if (value === 'delete') {
            setIsDisabled(false);
        }
    }

    const deleteClicked = () => {
        setDeleteConfirm('');
        submitDelete();
        handleClose();
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <WrapperDiv>
                <DialogTitle>
                    Delete Account
                </DialogTitle>
                <DialogContent>
                    <ContentDiv>
                        <TextDiv>
                            <Typography variant='body1'>
                                Are you sure you want to delete your account, {user.email}?
                            </Typography>
                            <Typography variant="body1">
                                If so, please type <strong>delete</strong> and click Delete.
                            </Typography>
                        </TextDiv>
                        <StyledInput
                            placeholder='delete'
                            label='delete'
                            value={deleteConfirm}
                            onChange={handleChange}
                        />
                    </ContentDiv>
                </DialogContent>
                <DialogActions sx={{ mr: '1rem' }}>
                    <StyledButton onClick={handleClose}>Cancel</StyledButton>
                    <StyledButton disabled={isDisabled} onClick={deleteClicked}>
                        Delete
                    </StyledButton>
                </DialogActions>
            </WrapperDiv>
        </Dialog>
    );
}

export default DeleteDialog;