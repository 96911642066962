import React from 'react';
import TopNav from "./TopNav";
import { styled } from '@mui/material/styles';
import { Outlet } from "react-router-dom";

const FlexWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '3.125rem',
}));

const WithNav = (toggleTheme) => {
    return (
        <React.Fragment>
        <TopNav />

        <FlexWrapper id="header-wrapper">
            <Outlet />
        </FlexWrapper>
        </React.Fragment>
    )
}

export default WithNav;