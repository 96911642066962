import { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Icon,
  useTheme,
  styled,
  Divider,
  Link
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { useGetUserQuery } from "../../utils/redux/api/userApi";
import { useGetProgramGroupsQuery } from "../../utils/redux/api/programApi";
import { useGetStaffQuery } from "../../utils/redux/api/programApi";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { getInitials } from "../../utils/helpers";
import { useCreateProgramPatientGroupMutation, useAddProgramUserMutation, useAddProgramStaffMutation } from '../../utils/redux/api/programApi';
import AddProgramGroupDialog from "./AddProgramGroupDialog";
import AddProgramStaffDialog from "./AddProgramStaffDialog";



const WrapperDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100vh",
  padding: theme.spacing(2),
  overflow: "hidden",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
    paddingRight: 0, // remove right padding on smaller screens
  },
}));
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

// const ContentDiv = styled("div")(({ theme }) => ({
//   width: "100%",
//   marginTop: "1rem",
// }));

const StaffDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  width: "max-content",
}));

const ContentDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
  marginBottom: "1rem",
  marginTop: "1rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const MainContent = styled("div")(({ theme }) => ({
  flex: "0.8",
  margin: theme.spacing(2),
}));

const Sidebar = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "0.2",
  height: "100%",
  margin: theme.spacing(2),
  marginBottom: theme.spacing(4),
  overflowY: "auto",
}));

const ProgramHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  paddingRight: theme.spacing(4), // add right padding
  width: "100%",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: theme.shadows[6], // add shadow
  border: `1px solid ${theme.palette.divider}`,
  boxSizing: "border-box", // ensure padding is included in width
  fontWeight: "bold", // make text bold
  letterSpacing: "0.1em", // increase spacing between letters
  textTransform: "uppercase", // transform text to uppercase
  marginTop: theme.spacing(2),
}));

const StaffCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1), // Reduce the padding
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  margin: theme.spacing(1), // Keep some margin to avoid sticking to the outer Card edges
  borderRadius: theme.shape.borderRadius, // Optional: use the theme's border radius
  border: `1px solid ${theme.palette.grey[600]}`,
}));

const AvatarWithBorder = styled(Avatar)(({ theme }) => ({
  bgcolor: theme.palette.background.default, // Adjusted for better contrast
  color: theme.palette.getContrastText(theme.palette.background.default),
  border: `2px solid ${theme.palette.primary.main}`, // A bit thicker for emphasis
  marginRight: theme.spacing(1), // Ensure it doesn't stick to the text
}));

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [staffDialogOpen, setStaffDialogOpen] = useState(false);
  const { data: user, isError, error, isLoading } = useGetUserQuery();
  const {
    data: programGroups,
    isError: isProgramGroupsError,
    error: programGroupsError,
  } = useGetProgramGroupsQuery(user?.programUser?.programId, { skip: !user });
  const {
    data: programStaff,
    isError: isProgramStaffError,
    error: programStaffError,
  } = useGetStaffQuery();
  const [createProgramPatientGroup] = useCreateProgramPatientGroupMutation();
  const [addProgramUser] = useAddProgramUserMutation();
  const [addProgramStaff] = useAddProgramStaffMutation();



  if (isLoading) {
    return <div>Loading...</div>; // Replace with your loading component or message
  }
  console.log(programGroups); // log the programGroups data

  const handleDialogSubmit = async (data) => {

    await createProgramPatientGroup(data);
    setDialogOpen(false);
  };

  const handleAddProgramStaff = async (newStaffData) => {
    // Call the mutation
    await addProgramStaff(newStaffData);
    setStaffDialogOpen(false);
  };

  // Check if the user is not a patient
  if (user && user.programUser && user.programUser.userType !== "patient") {
    return (
      <WrapperDiv>
        <ProgramHeader>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <Avatar src={user.programUser.program.programPictureUrl} />
          </IconButton>
          <Typography variant="h6">{user.programUser.program.name}</Typography>
          <Divider orientation="vertical" flexItem sx={{ marginLeft: theme.spacing(1) }} />
          <Link
            component={RouterLink}
            to={{
              pathname: "/program/patients"
            }}
            color={theme.palette.primary.contrastText}
            sx={{ mx: theme.spacing(1) }}
          >
            Patients
          </Link>


        </ProgramHeader>
        <ContentDiv>

          <MainContent>
            <Card sx={{ backgroundColor: theme.palette.optional.paper }}>
              <CardHeader
                title="Program Groups"
                action={
                  <IconButton
                    aria-label="add group"
                    sx={{ color: theme.palette.primary.contrastText }}
                    onClick={() => setDialogOpen(true)}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                }
                sx={{
                  backgroundColor: theme.palette.primary.light, // use primary color from your theme
                  color: theme.palette.primary.contrastText,
                  fontWeight: "bold",
                  letterSpacing: "0.1em",
                  textTransform: "uppercase",
                }}
              />
              <CardContent style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {programGroups &&
                  programGroups.groups.map((group, index) => (
                    <Card
                      key={index}
                      onClick={() =>
                        navigate(`/program/${user.programUser.programId}/group/${group.id}`)
                      }
                      sx={{
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.background.paper,
                        marginBottom: theme.spacing(2),
                        marginRight: theme.spacing(2),
                        width: '20rem', // set a fixed width for the cards
                        cursor: 'pointer', // change the cursor to a pointer when hovering over the card
                      }}
                    >
                      <CardContent>
                        <Typography variant="h5">
                          {group.restrictionType}
                        </Typography>
                        <Typography variant="h6"># of Patients: {group.patientCount}</Typography>
                      </CardContent>
                    </Card>
                  ))}
              </CardContent>
            </Card>
          </MainContent>
          <Sidebar>
            <Card sx={{ height: "100%", marginBottom: "4rem", backgroundColor: theme.palette.optional.paper }}>
              <CardHeader
                title="Program Staff"
                action={
                  <IconButton
                    aria-label="add staff"
                    sx={{ color: theme.palette.primary.contrastText }}
                    onClick={() => setStaffDialogOpen(true)}
                  >
                    <PersonAddAlt1Icon />
                  </IconButton>
                }
                sx={{
                  backgroundColor: theme.palette.primary.light, // use primary color from your theme
                  color: theme.palette.primary.contrastText,
                  fontWeight: "bold",
                  letterSpacing: "0.1em",
                  textTransform: "uppercase",
                }}
              />
              <CardContent>
                {programStaff &&
                  programStaff.programStaff.map((staff, index) => (
                    <StaffCard key={index}>
                      <CardHeader
                        title={staff.displayName}
                        action={
                          <AvatarWithBorder
                            src={staff.profilePictureUrl}
                            alt={staff.displayName}
                          >
                            {getInitials(staff.displayName)}
                          </AvatarWithBorder>
                        }
                      />
                      <CardContent>
                        <Typography variant="body1">
                          Email: {staff.email}
                        </Typography>
                        <Typography variant="body1">
                          User Type: {staff.userType}
                        </Typography>
                      </CardContent>
                    </StaffCard>
                  ))}
              </CardContent>
            </Card>
          </Sidebar>
        </ContentDiv>
        <AddProgramGroupDialog
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          programId={user.programUser.programId}
          onSubmit={handleDialogSubmit}
        />
        <AddProgramStaffDialog
          open={staffDialogOpen}
          onClose={() => setStaffDialogOpen(false)}
          onAdd={handleAddProgramStaff}
          programId={user.programUser.programId}
        />
      </WrapperDiv>
    );
  } else {
    // Redirect the user or show an error message
    navigate("/");
  }
};

export default Home;
