// ChatWindow.jsx
import { useEffect, useRef } from 'react';
import { Typography, TextField, Button, styled, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useGetUserQuery } from '../../utils/redux/api/userApi';

const ChatWindowContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '30rem',
    width: '100%',
    border: '1px solid #ddd',
    borderRadius: '5px',
    overflowY: 'auto', // allow scrolling if the content overflows
    position: 'relative', // add this line
    zIndex: 1, // add this line
}));


const MessageHistoryDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: theme.spacing(2),
    borderBottom: '1px solid #ddd',
}));

const HeaderDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: '1px solid #ddd',
    backgroundColor: theme.palette.secondary.light,
}));

const MessageSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper, // add a background color
    border: '1px solid #ddd', // add a border
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // add some shadow
    borderRadius: '5px', // optional: add some border radius
}));

const MessageInputDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
}));

const UserMessage = styled('div')(({ theme }) => ({
    alignSelf: 'flex-end',
    backgroundColor: theme.palette.chat.userMessage,
    color: theme.palette.chat.userMessageText,
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: '80%',
}));

const OtherMessage = styled('div')(({ theme }) => ({
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.chat.otherMessage,
    color: theme.palette.chat.otherMessageText,
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: '80%',
}));

export default function ChatWindow({ activeConversation, message, setMessage, handleSendMessage, setActiveConversation }) {
    const bottomRef = useRef();
    const { data: user } = useGetUserQuery();

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [activeConversation.messages]);

    const handleClose = () => {
        setActiveConversation(null);
    };
    console.log(user.displayName)
    return (
        <ChatWindowContainer>
            <HeaderDiv>
                <Typography variant="h6">{activeConversation.subject}</Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </HeaderDiv>
            <MessageSection>
                {activeConversation.messages.map((message, index) => {
                    const MessageComponent = message.sender === user.displayName ? UserMessage : OtherMessage;
                    return (
                        <MessageComponent key={index}>

                            <Typography variant="body2">
                                {message.sender || 'Unknown'}: {message.text}
                            </Typography>

                        </MessageComponent>
                    );
                })}
                <div ref={bottomRef} />
            </MessageSection>
            <MessageInputDiv>
                <TextField
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault(); // Prevents the addition of a new line in the TextField by default
                            handleSendMessage();
                        }
                    }}
                    placeholder="Type a message"
                    fullWidth
                />
                <Button variant="contained" color="primary" onClick={handleSendMessage} style={{ marginLeft: '16px' }}>
                    Send
                </Button>
            </MessageInputDiv>
        </ChatWindowContainer>
    );
}