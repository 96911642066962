import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { hydrateThemeFromStorage, toggleMode, setThemeMode } from './utils/redux/slice/themeSlice';
import CssBaseline from '@mui/material/CssBaseline';
import ContactUs from './components/ContactUs';
// import TopNav from './components/navigation/TopNav';
import Footer from './components/navigation/Footer';
import WithNav from './components/navigation/WithNav';
import WithoutNav from './components/navigation/WithoutNav';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Recipe from './components/Recipe';
import Register from './components/auth/Register'
import Verify from './components/auth/Verify';
import Login from './components/auth/Login';
import PasswordReset from './components/auth/PasswordReset';
import RequestPasswordReset from './components/auth/RequestPasswordReset';
import RequestDelete from './components/auth/RequestDelete';
import ProviderHome from './components/provider/Home';
import Group from './components/provider/Group';
import PatientProfile from './components/provider/PatientProfile';
import ProgramUsers from './components/provider/ProgramUsers';
import AcceptInvitePage from './components/provider/AccpetInvite';
import { darkTheme, lightTheme } from './theme/MainTheme';

const FlexWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
}));



function App() {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.theme.mode);



  useEffect(() => {
    const storedThemeMode = localStorage.getItem('theme');
    if (storedThemeMode) {
      const mode = JSON.parse(storedThemeMode).mode; // Ensure to parse the mode since it's stored as a string
      dispatch(setThemeMode(mode)); // Dispatch the action to update the state with the hydrated theme
    }
  }, [dispatch]);

  const appliedTheme = createTheme(themeMode === 'dark' ? darkTheme : lightTheme);

  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline enableColorScheme />
      <FlexWrapper id="flex-wrapper">
        <Routes>
          <Route element={<WithoutNav />} >
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/reset-password" element={<PasswordReset />} />
            <Route path="/password-reset" element={<RequestPasswordReset />} />


          </Route>
          <Route element={<WithNav />}>
            <Route path="/recipe" element={<Recipe />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/request-deletion" element={<RequestDelete />} />
            <Route path="/program" element={<ProviderHome />} />
            <Route path="/program/:programId/group/:groupId" element={<Group />} />
            <Route path="/program/patient/:id" element={<PatientProfile />} />
            <Route path="/program/patients" element={<ProgramUsers />} />
            <Route path="/program/accept-invite" element={<AcceptInvitePage />} />
          </Route>
        </Routes>
        <Footer />
      </FlexWrapper>
    </ThemeProvider>

  );
}
export default App;
