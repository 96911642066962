import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, styled, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, Box, Typography, IconButton } from "@mui/material";
import RecipeDialog from '../common/RecipeDialog';
import { usePostNewConversationMutation } from '../../utils/redux/api/messagingApi'; // import the mutation hook
import { useGetUserQuery } from '../../utils/redux/api/userApi';
import NewConversationDialog from '../common/NewConversationDialog';
import { openDialog, closeDialog } from '../../utils/redux/slice/conversationSlice'; // import the actions
import FullscreenIcon from '@mui/icons-material/Fullscreen';




const WrapperDiv = styled('div')(({ theme }) => ({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowY: 'auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.paper,
    },
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
    '& .MuiTableCell-root': {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
    },
}));

const TitleDiv = styled('div')(({ theme, maximized }) => ({
    display: 'flex',
    justifyContent: maximized ? 'center' : 'space-between',
    alignItems: 'center',
}));




const MealPlan = ({ mealPlans, maximized, onMaximize }) => {

    const dispatch = useDispatch();
    const { data: user, isLoading, isError } = useGetUserQuery();
    const [postConversation] = usePostNewConversationMutation();
    const conversation = useSelector((state) => state.conversation);
    const patientId = useSelector((state) => state.patient.patientId);
    const [open, setOpen] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [openRow, setOpenRow] = useState(null);
    const [openDay, setOpenDay] = useState(null);
    const [selectedMealPlan, setSelectedMealPlan] = useState(null);

    const handleClickOpen = (recipe) => {
        setSelectedRecipe(recipe);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleDiscussClick = (mealPlan) => {
        setSelectedMealPlan(mealPlan);
    };

    useEffect(() => {
        const handleNewConversationOpen = () => {
            dispatch(openDialog({})); // dispatch the openDialog action
        };
        if (selectedMealPlan) {
            handleNewConversationOpen();
        }
    }, [dispatch, selectedMealPlan]);
    const handleCreateConversation = async (conversation) => {
        try {
            // Use the mutation hook to send the data
            if (!conversation.participants.includes(user.id)) {
                // Add patient_id to the participants
                conversation.participants.push(user.id);
            }
            if (!conversation.participants.includes(patientId)) {
                // Add patient_id to the participants
                conversation.participants.push(patientId);
            }
            conversation.mealPlanId = selectedMealPlan.id;
            console.log(conversation);
            await postConversation(conversation).unwrap();
            dispatch(closeDialog()); // dispatch the closeDialog action
        } catch (error) {
            console.error('Failed to create conversation', error);
        }
    };

    const handleConversationClose = async () => {
        setSelectedMealPlan(null);
        dispatch(closeDialog())
    }

    const DayTableRow = styled(TableRow)(({ theme, dayIndex }) => ({
        backgroundColor: dayIndex % 2 === 0 ? theme.palette.action.selected : theme.palette.background.paper,
    }));

    const RecipeTableRow = styled(TableRow)(({ theme, recipeIndex }) => ({
        backgroundColor: recipeIndex % 2 === 0 ? theme.palette.action.hover : theme.palette.background.paper,
    }));

    return (
        <WrapperDiv>
            <TitleDiv maximized={maximized}>

                {!maximized && (
                    <div></div>
                )}
                <Typography variant="h4" gutterBottom alignSelf={'center'}>
                    Meal Plans
                </Typography>
                {!maximized && (
                    <IconButton onClick={onMaximize}>
                        <FullscreenIcon />
                    </IconButton>
                )}
            </TitleDiv>
            <StyledTableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <StyledHeaderRow>
                            <TableCell>Start Date</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>Recipes</TableCell>
                            <TableCell></TableCell>
                        </StyledHeaderRow>
                    </TableHead>
                    <TableBody>
                        {mealPlans.map((mealPlan, index) => {
                            // Group recipes by day
                            const days = mealPlan.meal_plan_recipes_associations.reduce((acc, curr) => {
                                if (!acc[curr.day]) {
                                    acc[curr.day] = [];
                                }
                                acc[curr.day].push(curr.recipe);
                                return acc;
                            }, {});

                            return (
                                <React.Fragment key={index}>
                                    <StyledTableRow>
                                        <TableCell>
                                            {new Date(mealPlan.startDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </TableCell>
                                        <TableCell>{mealPlan.isActive ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => setOpenRow(openRow === index ? null : index)}>
                                                {openRow === index ? 'Hide Days' : 'Show Days'}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={() => handleDiscussClick(mealPlan)}>
                                                Discuss
                                            </Button>
                                        </TableCell>
                                    </StyledTableRow>
                                    {openRow === index && Object.entries(days).map(([day, recipes], dayIndex) => (
                                        <React.Fragment key={dayIndex}>
                                            <DayTableRow dayIndex={dayIndex}>
                                                <TableCell style={{ paddingLeft: '30px' }} colSpan={3}>
                                                    <Button onClick={() => setOpenDay(openDay === dayIndex ? null : dayIndex)}>
                                                        {`Day ${day}`}
                                                    </Button>
                                                </TableCell>
                                            </DayTableRow>
                                            {recipes.map((recipe, recipeIndex) => (
                                                <RecipeTableRow recipeIndex={recipeIndex}>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={openDay === dayIndex} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={6} md={4}>
                                                                        <Button color="primary" onClick={() => handleClickOpen(recipe)}>
                                                                            {recipe.title}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </RecipeTableRow>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <RecipeDialog open={open} handleClose={handleClose} recipe={selectedRecipe} />
            {selectedMealPlan && (
                <NewConversationDialog
                    open={conversation.open}
                    onClose={handleConversationClose}
                    onCreate={handleCreateConversation}
                    initialSubject={`Meal Plan: ${new Date(selectedMealPlan.startDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    })}`}
                />
            )}
        </WrapperDiv>
    );
};

export default MealPlan;