export const cuisines = ['American', 'Italian', 'Mexican', 'Chinese', 'Indian', 'Japanese', 'Thai',
    'French', 'Greek', 'Vietnamese', 'Korean', 'Spanish', 'Lebanese', 'Moroccan', 'Turkish', 'Brazilian',
    'Ethiopian', 'Peruvian', 'Russian', 'Ukrainian', 'German', 'Polish', 'Portuguese', 'Indonesian', 'Filipino',
    'Malaysian', 'Pakistani', 'Sri Lankan', 'Israeli', 'Arabic', 'Iranian', 'Afghan', 'Bangladeshi', 'Nepalese',
    'Mongolian', 'Uzbek', 'Kazakh', 'Armenian', 'Georgian', 'Azerbaijani', 'Kurdish', 'Sudanese', 'Nigerian',
    'Egyptian', 'South African', 'Tanzanian', 'Kenyan', 'Ugandan', 'Somali', 'Ghanaian', 'Senegalese', 'Ivorian',
    'Algerian', 'Libyan', 'Mauritanian', 'Malian', 'Sierra Leonean', 'Gambian', 'Cape Verdean', 'Mozambican', 'Angolan',
    'Zimbabwean', 'Namibian', 'Botswanan', 'Malawian'];

export const allergies = [
    'Milk', 'Eggs', 'Peanuts', 'Tree nuts', 'Soy', 'Wheat', 'Fish', 'Shellfish', 'Sesame', 'Mustard', 'Celery', 'Lupin', 'Sulfites', 'Corn', 'Red meat', 'Chicken', 'Turkey', 'Rice', 'Buckwheat', 'Quinoa', 'Oats', 'Coconut', 'Sunflower seeds', 'Poppy seeds', 'Kiwi fruit'];