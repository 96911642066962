import { isFulfilled } from '@reduxjs/toolkit';
import { api } from '../api/base';
import { resetTimer, triggerLogout } from '../slice/inactivitySlice'; // import the triggerLogout action

const refreshSessionMiddleware = (postLoginFulfilledMatcher, postLogoutFulfilledMatch) => (storeAPI) => (next) => (action) => {

    // If the action is the postLogout action, skip the session refresh logic
    if (postLogoutFulfilledMatch(action)) {
        console.log('logout')
        return next(action);
    }

    if (postLoginFulfilledMatcher(action)) {
        storeAPI.dispatch(resetTimer());
        return next(action);
    }


    // If the action is a fulfilled query or mutation action, trigger the refreshSession mutation
    if (isFulfilled(action) && (action.type.startsWith(`${api.reducerPath}/executeQuery`) || action.type.startsWith(`${api.reducerPath}/executeMutation`))) {
        const oneMinute = 60 * 1000;  // One minute in milliseconds

        // If a minute has passed since the last refreshSession call
        if (Date.now() - storeAPI.getState().inactivity.lastActiveTime >= oneMinute) {
            // If the action is not the refreshSession action, trigger the refreshSession mutation
            if (action.type !== api.endpoints.refreshSession.initiate().type) {
                storeAPI.dispatch(api.endpoints.refreshSession.initiate());

                // Dispatch the resetTimer action
                storeAPI.dispatch(resetTimer());
            }
        }
    }

    // Pass the action to the next middleware in the chain
    return next(action);
};

export default refreshSessionMiddleware;