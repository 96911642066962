import { useEffect, useState, useMemo } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import background from "../resources/vegetables.jpg";
import IngredientsPanel from "./IngredientsPanel";
import { fetchRecipe } from "../apis/recipe";
import ScrollDiv from "./ScrollDiv";

const WrapperDiv = styled("div")(({ theme }) => ({
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  minHeight: "fit-content",
  maxHeight: "100%",
  paddingBottom: "3rem",
  backgroundColor: theme.palette.background.paper,
  //backgroundImage: `url(${background})`,
  //backgroundSize: "cover",
  //backgroundRepeat: "no-repeat",
  "& >>*": {
    marginBottom: "1rem",
  },
}));

const TitleDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "80%",
  justifyContent: "center",
  backgroundColor: '#2a9d8f1A',
  padding: "1rem",
  borderRadius: "5px",
  boxShadow: theme.shadows[3],
}));

const SectionDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  backgroundColor: theme.palette.background.paper,
  padding: "1rem",
  borderRadius: "5px",
  boxShadow: theme.shadows[3],
  "& > h6": {
    marginBottom: "0.5rem",
  },
  "& > div": {
    marginBottom: "0.5rem",
  },
  marginBottom: '1rem',
}));

const IngredientDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const ServingDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "center",
  marginBottom: "1rem",
}));

const NutritionDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const NotesDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const InstructionDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const Card = styled(SectionDiv)(({ theme }) => ({
  backgroundColor: '#2a9d8f1A', // Lighter shade of your secondary color with reduced opacity
  border: '1px solid #2a9d8f33', // Border with a bit of transparency
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Box shadow to lift the card visually
  borderRadius: '8px', // Optional: for rounded corners
  padding: theme.spacing(2), // Inner padding
  marginBottom: theme.spacing(2), // Margin at the bottom for separation between cards
  '& .even': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const RecipeViewer = ({ recipe }) => {
  console.log(recipe);
  const ingredients = typeof recipe.ingredients === 'string'
    ? JSON.parse(recipe.ingredients)
    : recipe.ingredients;
  const nutrition = typeof recipe.nutrition === 'string'
    ? JSON.parse(recipe.nutrition)
    : recipe.nutrition;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (recipe.version === "1") {
    return (
      <WrapperDiv>
        <TitleDiv>
          <Typography variant="h4">{recipe.title}</Typography>
        </TitleDiv>
        <ServingDiv>
          <Typography variant="h6">{recipe.servings}</Typography>
        </ServingDiv>
        <ScrollDiv>
          <IngredientDiv>
            <Typography variant="h6">Ingredients:</Typography>
            <Typography
              variant="body"
              whiteSpace="pre-line"
              sx={{ ml: "1rem" }}
            >
              {recipe.ingredients.split("\n").slice(1).join("\n")}
            </Typography>
          </IngredientDiv>
          <InstructionDiv>
            <Typography variant="h6">Instructions:</Typography>
            <Typography
              variant="body"
              whiteSpace="pre-line"
              sx={{ ml: "1rem" }}
            >
              {recipe.instructions.split("\n").slice(1).join("\n")}
            </Typography>
          </InstructionDiv>
          <NutritionDiv>
            <Typography variant="h6">Nutrition (Per Serving):</Typography>
            <Typography
              variant="body"
              whiteSpace="pre-line"
              sx={{ ml: "1rem" }}
            >
              {recipe.nutrition.split("\n").slice(1).join("\n")}
            </Typography>
          </NutritionDiv>
          <NotesDiv>
            <Typography variant="h6">Notes:</Typography>
            <Typography
              variant="body"
              whiteSpace="pre-line"
              sx={{ ml: "1rem" }}
            >
              {recipe.notes.split("\n").slice(1).join("\n")}
            </Typography>
          </NotesDiv>
        </ScrollDiv>
      </WrapperDiv>
    );
  } else {
    const nutritionInfo = [
      { label: 'Calories', value: nutrition.calories },
      { label: 'Protein', value: nutrition.protein },
      { label: 'Carbohydrates', value: nutrition.total_carbs?.total || nutrition.carbohydrates },
      { label: 'Fat', value: nutrition.total_fat?.total || nutrition.fat },
      { label: 'Saturated Fat', value: nutrition.total_fat?.saturated_fat },
      { label: 'Monounsaturated Fat', value: nutrition.total_fat?.unsaturated_fat?.monounsaturated_fat },
      { label: 'Polyunsaturated Fat', value: nutrition.total_fat?.unsaturated_fat?.polyunsaturated_fat },
      { label: 'Trans Fat', value: nutrition.total_fat?.trans_fat },
      { label: 'Fiber', value: nutrition.total_carbs?.fiber },
      { label: 'Total Sugars', value: nutrition.total_carbs?.total_sugars?.total },
      { label: 'Added Sugars', value: nutrition.total_carbs?.total_sugars?.added_sugars },
      { label: 'Sugar Alcohols', value: nutrition.total_carbs?.sugar_alcohols },
      { label: 'Cholesterol', value: nutrition.cholesterol },
      { label: 'Sodium', value: nutrition.sodium },
      // ... add the rest of your nutrition info here
    ];
    return (
      <WrapperDiv>
        <TitleDiv>
          <Typography variant="h4">{recipe.title}</Typography>
        </TitleDiv>
        <ServingDiv>
          <Typography variant="h6">{recipe.servings}</Typography>
        </ServingDiv>
        <ScrollDiv>
          <Card>
            <Typography variant="h6">Ingredients:</Typography>
            {Object.entries(ingredients).map(
              ([ingredient, quantity], index) => (
                <div key={index}>
                  <Typography variant="body2">
                    <strong>{capitalizeFirstLetter(ingredient)}</strong>:{" "}
                    {quantity}
                  </Typography>
                </div>
              )
            )}
          </Card>
          <Card>
            <Typography variant="h6">Instructions:</Typography>
            {recipe.instructions.map((instruction, index) => (
              <div key={index}>
                <Typography variant="body2">{instruction}</Typography>
              </div>
            ))}
          </Card>
          <Card>
            <Typography variant="h6">Nutrition (Per Serving):</Typography>
            {nutritionInfo.map((info, index) => (
              info.value && (
                <div key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
                  <Typography variant="body2">
                    <strong>{info.label}</strong>: {info.value}
                  </Typography>
                </div>
              )
            ))}
          </Card>
          <Card>
            <Typography variant="h6">Notes:</Typography>
            {recipe.notes.map((note, index) => (
              <div key={index}>
                <Typography variant="body2">{note}</Typography>
              </div>
            ))}
          </Card>
        </ScrollDiv>
      </WrapperDiv>
    );
  }
};

export default RecipeViewer;
