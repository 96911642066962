import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { StyledButton, StyledInput } from '../StyledInputs';
import { useGetUserQuery, usePostLoginMutation } from '../../utils/redux/api/userApi';


const TextDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    marginBottom: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    '& form': {
        display: 'flex',
        flexDirection: 'column',
        '& >*:not(:last-child)': {
            marginBottom: '1rem',
        }
    },
}));

const ActionDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
}));

const ContentDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'inherit',
    marginLeft: '1rem',
    overflow: 'hidden',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
    },
}));

const InputDiv = styled('div')(({ theme }) => ({
    justifyContent: 'center',
    display: 'flex',
    marginTop: '1rem',
    flexDirection: 'row',
    paddingLeft: '2rem',
    paddingRight: '1rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    width: '30rem',
    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const FormDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '1rem',
    backgroundColor: 'rgba(128,128,128, 0.2)',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const AppDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '4rem',
    width: 'auto',
    justifyContent: 'center',
    '& .gplay': {
        marginLeft: '1rem',
    },
    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const CheckboxDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '3rem',
    paddingRight: '2rem',
    paddingBottom: '1rem',
    width: '30rem',
    '& .MuiTypography-body1': {
        fontSize: '1rem',

    },
    '& .MuiCheckbox-root': {
        color: '#333',
        '&.Mui-checked': {
            color: '#2ecc71',
        },
    },

    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const LandingDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
}));

const LandingHeader = styled('header')(({ theme }) => ({
    textAlign: 'center',
    marginBottom: '2rem',
    '& h1': {
        fontSize: '3rem',
    },
    '& .MuiTypography-subtitle1': {
        fontSize: '1.5rem',
        color: '#333',
    }
}));

const MainDiv = styled('main')(({ theme }) => ({
    textAlign: 'center',
    marginBottom: '2rem',
    '& h2': {
        fontSize: '2rem',
        color: '#333',
    },
    '& .MuiTypography-body1': {
        fontSize: '1.2rem',
        color: '#777',
    },
    '& .get-started': {
        marginTop: '1rem',
        padding: '1rem 2rem',
        fontSize: '1.2rem',
        backgroundColor: '#2ecc71',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        animation: 'pulse 2s infinite',
    },
    '& .join-button': {
        backgroundColor: '#2ecc71',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    '& h4': {
        marginTop: '1rem',
        fontSize: '1.5rem',
        color: '#333',
        '&.not-joined': {
            cursor: 'pointer',
            textDecoration: 'underline',

        }
    },
}));


const Login = () => {
    const location = useLocation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [loginSuccessful, setLoginSuccessful] = useState(false);
    const navigate = useNavigate();
    const [postLogin, { isLoading, isSuccess }] = usePostLoginMutation();


    // useEffect(() => {
    //     if (isError) {
    //         setErrorMessage(error.message);
    //     }
    // }, [isError, error]);

    const clearFields = () => {
        setUsername('');
        setPassword('');
        setErrorMessage(null);
    };

    const handleLogin = async () => {
        console.log('here');
        try {
            console.log('posting login');
            await postLogin({ username, password });

        } catch (error) {
            setErrorMessage(error.message);
        }
    };


    const handleForgotPassword = () => {
        navigate("/password-reset")
    };

    const { data: user, isError, error } = useGetUserQuery();

    useEffect(() => {
        if (isSuccess) {
            clearFields();
            // Check if the state property exists and if it has a from property
            if (location.state && location.state.from && location.state.from !== 'home') {
                // Navigate the user back to the previous location
                navigate(location.state.from);
            } else {
                // If the state property does not exist, navigate the user to the default page
                navigate('/recipe');
            }
        }
    }, [isSuccess, navigate, location]);

    if (user) {
        return <Navigate replace to="/recipe" />
    } else {
        return (
            <LandingDiv>
                <LandingHeader>
                    <Typography variant='h1'>Login</Typography>
                </LandingHeader>
                <MainDiv>
                    <TextDiv>
                        <form id="login-form" onSubmit={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleLogin();
                        }}>
                            <StyledInput
                                fullWidth
                                placeholder='Email'
                                label="Email"
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                }}
                            />
                            <StyledInput
                                fullWidth
                                placeholder='Password'
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                            />
                        </form>
                        <Button variant='text' onClick={handleForgotPassword} sx={{ mt: '1rem' }}>Forgot Password?</Button>
                    </TextDiv>
                    {errorMessage &&
                        <Typography variant='body'>{errorMessage}</Typography>}
                </MainDiv>
                <StyledButton type="submit" form="login-form">
                    Submit
                </StyledButton>
                <StyledButton onClick={() => navigate('/register')}>
                    Register
                </StyledButton>
            </LandingDiv>
        );
    }
};

export default Login;
