import { baseUrl } from './utils';

export const addEmailToMailingList = async (data) => {
    const resp = await fetch(baseUrl + '/general/mailinglist', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    });

    console.log('mailinglist resp: ', resp)
    return resp;
}