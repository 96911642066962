import { useState, useEffect, Fragment } from "react";
import {
  TextField,
  Button,
  styled,
  Typography,
  IconButton,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Link,
  useTheme,
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Form, useLocation, useNavigate } from "react-router-dom";
import { Link as RouterLink, useParams } from 'react-router-dom';

import EditIcon from "@mui/icons-material/Edit";
import Goals from "./Goals.jsx";
import InvitePatientDialog from './InvitePatientForm.jsx';
import { useUpdateProgramPatientGroupMutation, useGetProgramPatientGroupPatientsQuery, useGetProgramGroupsQuery, useCreatePatientInviteMutation } from "../../utils/redux/api/programApi.jsx";
import { getInitials } from "../../utils/helpers.js";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  alignItems: 'center',
  overflow: "hidden",
  height: '100%',
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
    paddingRight: 0, // remove right padding on smaller screens
  },
}));

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
  gap: "1rem",
  marginBottom: theme.spacing(2),
  overflow: 'hidden',
}));

const DietRestrictionContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  alignItems: "center",
});

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: 'auto',
  flex: 1,
  // alignItems: 'flex-end',
  marginBottom: theme.spacing(8),
}));

const FormContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2),
  width: '100%',
  gap: "1rem",
  flex: .5,
  overflowY: "auto", // Add this line
  border: `1px solid ${theme.palette.divider}`, // Add a border
  borderRadius: theme.shape.borderRadius, // Adjust border radius
  backgroundColor: theme.palette.background.paper, // Add a background color
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.scrollbar.track,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.scrollbar.thumb,
  },
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.scrollbar.thumb} ${theme.palette.scrollbar.track}`,
}));

const GoalContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: '100%',
  flex: 1,
  position: 'relative',
  height: '100%',
  border: `1px solid ${theme.palette.divider}`, // Add a border
  borderRadius: theme.shape.borderRadius, // Adjust border radius
  backgroundColor: theme.palette.background.paper, // Add a background color
}));

const PatientCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1), // Reduce the padding
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  margin: theme.spacing(1), // Keep some margin to avoid sticking to the outer Card edges
  borderRadius: theme.shape.borderRadius, // Optional: use the theme's border radius
  border: `1px solid ${theme.palette.grey[600]}`,
  cursor: 'pointer',
}));

const AvatarWithBorder = styled(Avatar)(({ theme }) => ({
  bgcolor: theme.palette.background.default, // Adjusted for better contrast
  color: theme.palette.getContrastText(theme.palette.background.default),
  border: `2px solid ${theme.palette.primary.main}`, // A bit thicker for emphasis
  marginRight: theme.spacing(1), // Ensure it doesn't stick to the text
}));

const TileContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: theme.spacing(1),
  flexWrap: "wrap",
  padding: theme.spacing(2),
  width: '100%',
  height: '100%',
}));

const PatientListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: '100%',
  flex: .5,
  position: 'relative',
  height: '100%',
  border: `1px solid ${theme.palette.divider}`, // Add a border
  borderRadius: theme.shape.borderRadius, // Adjust border radius
  backgroundColor: theme.palette.background.paper, // Add a background color
}));

const PatientListHeader = styled("div")(({ theme }) => ({
  width: '100%',
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const EditButton = styled(IconButton)({
  position: "absolute", // Position the button absolutely
  top: "0", // Align the button with the top of the TileContainer
  right: "0", // Align the button with the right of the TileContainer
});


const Tile = styled("div")(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center", // Center the text horizontally
  justifyContent: "center", // Center the text vertically
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  minWidth: "8rem",
  maxHeight: "8rem",
  textAlign: "center", // Center the text
}));

const Group = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { programId, groupId } = useParams(); // Assuming you're using react-router and the group ID is a URL parameter
  const { data: groups } = useGetProgramGroupsQuery(programId);
  const [inviteOpen, setInviteOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  const [group, setGroup] = useState(null);
  const [goals, setGoals] = useState([]); // Add a goals state variable
  const [isOpen, setOpen] = useState(false);
  const [updateProgramPatientGroup] = useUpdateProgramPatientGroupMutation();
  const { data: patients, error, isLoading: patientsIsLoading } = useGetProgramPatientGroupPatientsQuery({ programId, groupId });
  const [createPatientInvite, { isLoading: isCreatingInvite }] = useCreatePatientInviteMutation();
  console.log(`patients: ${patients}`);

  const handleClose = () => {
    setInviteOpen(false);
  }

  const handleInvite = async (patientEmail, programPatientGroupId) => {
    try {
      const response = await createPatientInvite({ patientEmail, programPatientGroupId });
      console.log(`Invitation created: ${response.data}`);
      setOpen(false);
    } catch (error) {
      console.error('Failed to create invitation:', error);
    }
  };

  // useEffect(() => {
  //   if (!isLoading) {
  //     const updatedGroup = groups.groups.find(group => group.id === groupId);
  //     setGroup(updatedGroup);
  //   }
  // }, [groups, groupId, isLoading]);



  useEffect(() => {
    if (groups) {
      const updatedGroup = groups.groups.find(group => group.id === groupId);
      setGroup(updatedGroup);
      setGoals(updatedGroup.goals);
      setIsLoading(false); // Set loading to false after the group data has been fetched
    }
  }, [groups, groupId]);

  const handleChange = (event) => {
    setGroup({
      ...group,
      [event.target.name]: event.target.value,
    });
  };
  if (isLoading || !group) {
    return <div>Loading...</div>;
  }
  const handleDietRestrictionsChange = (event, index) => {
    const newDietRestrictions = [...group.dietRestrictions];
    newDietRestrictions[index] = event.target.value;
    setGroup({
      ...group,
      dietRestrictions: newDietRestrictions,
    });
  };

  const handleAddDietRestriction = () => {
    setGroup({
      ...group,
      dietRestrictions: Array.isArray(group.dietRestrictions) ? [...group.dietRestrictions, ""] : [""],
    });
  };

  const handleRemoveDietRestriction = (index) => {
    setGroup({
      ...group,
      dietRestrictions: group.dietRestrictions.filter((_, i) => i !== index),
    });
  };

  const handleSaveChanges = async () => {
    try {
      const updatedGroup = {
        programPatientGroupId: group.id,
        restrictionType: group.restrictionType,
        goals: Array.isArray(goals) ? null : goals,
        dietRestrictions: group.dietRestrictions
      };
      console.log(updatedGroup)
      await updateProgramPatientGroup({ updateData: updatedGroup });
    } catch (error) {
      console.error(error);
    }
  };

  const handlePatientCard = (patient) => {
    console.log(patient);
    navigate(`/program/patient/${patient.patientId}`, { state: { patient, goals, dietRestrictions: group.dietRestrictions } });
  }

  console.log(`goals: ${goals}`)

  return (
    <Container>
      <Goals
        open={isOpen}
        handleClose={() => setOpen(false)}
        goals={goals}
        setGoals={setGoals}
      />
      <Typography variant="h4">Group Details</Typography>
      <ContentContainer>
        <FormContainer>
          <div>
            <TextField label="Program ID" value={group.programId} disabled />
          </div>
          <div>
            <TextField
              label="Restriction Type"
              value={group.restrictionType}
              onChange={handleChange}
              name="restrictionType"
            />
          </div>
          {group.dietRestrictions && group.dietRestrictions.map((restriction, index) => (
            <DietRestrictionContainer key={index}>
              <TextField
                fullWidth
                label={`Diet Restriction ${index + 1}`}
                value={restriction}
                onChange={(event) => handleDietRestrictionsChange(event, index)}
              />
              <Button onClick={() => handleRemoveDietRestriction(index)}>
                Remove
              </Button>
            </DietRestrictionContainer>
          ))}
          <Button onClick={handleAddDietRestriction}>
            Add Diet Restriction
          </Button>
        </FormContainer>
        <GoalContainer>

          <Typography variant="h4" component="h2" style={{ textAlign: 'center' }}>
            Goals
          </Typography>
          <EditButton onClick={() => setOpen(true)}>
            <EditIcon />
          </EditButton>

          <TileContainer>

            {goals && Object.entries(goals).map(
              ([goalKey, { operator, minValue, maxValue, value }], index) => {
                // Translate the operator into symbols
                let operatorSymbol;
                switch (operator) {
                  case "lte":
                    operatorSymbol = "≤";
                    break;
                  case "gte":
                    operatorSymbol = "≥";
                    break;
                  case "eq":
                    operatorSymbol = "=";
                    break;
                  case "range":
                    operatorSymbol = "Range";
                    break;
                  default:
                    operatorSymbol = operator;
                }

                const formattedGoalKey = goalKey
                  .replace(/_/g, ' ')
                  .split(' ')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ');

                return (
                  <Tile key={index}>
                    <Typography variant="h6">{formattedGoalKey}</Typography>
                    <Typography>{operatorSymbol}</Typography>
                    {operator === 'range' ? (
                      <Fragment>
                        <Typography>{minValue} - {maxValue}</Typography>
                      </Fragment>
                    ) : (
                      <Typography>{value}</Typography>
                    )}
                  </Tile>
                );
              }
            )}
          </TileContainer>
        </GoalContainer>

        <PatientListContainer>
          <PatientListHeader>
            <span></span>
            <Typography variant="h4" component="h2" style={{ textAlign: 'center' }}>Patients</Typography>
            <IconButton
              aria-label="add patient"
              sx={{ color: theme.palette.primary.contrastText }}
              onClick={() => setInviteOpen(true)}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </PatientListHeader>
          {patients && patients.patients.map((patient, index) => (
            <PatientCard key={index} onClick={() => handlePatientCard(patient)}>
              <CardHeader
                title={patient.displayName}
                action={
                  <AvatarWithBorder
                    src={patient.profilePictureUrl}
                    alt={patient.displayName}
                  >
                    {getInitials(patient.displayName)}
                  </AvatarWithBorder>
                }
              />
              <CardContent>
                <Typography variant="body1">
                  <Link component={RouterLink} to={`mailto:${patient.email}`} title="Send email to patient">
                    {patient.email}
                  </Link>
                </Typography>
              </CardContent>
            </PatientCard>
          ))}
        </PatientListContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button variant="contained" color="primary" sx={{ height: '3rem' }} onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </ButtonContainer>
      <InvitePatientDialog
        groups={[group]}
        open={inviteOpen}
        handleClose={handleClose}
        handleInvite={handleInvite}
      />
    </Container>
  );
};

export default Group;
