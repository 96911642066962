import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAcceptInviteMutation } from '../../utils/redux/api/programApi';
import { Button, Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
});

const AcceptInvitePage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const invite_id = queryParams.get('invite_id');
    const navigate = useNavigate();
    const [acceptInvite, { isLoading }] = useAcceptInviteMutation();

    const handleAccept = async () => {
        try {
            await acceptInvite(invite_id).unwrap();
            navigate('/recipe');
        } catch (error) {
            console.error('Failed to accept invitation:', error);
        }
    };

    return (
        <StyledContainer>
            <Typography variant="h4" gutterBottom>Accept Invitation</Typography>
            <Typography variant="body1">You have been invited to join the Program.</Typography>
            <Box mt={3}>
                <Button variant="contained" color="primary" onClick={handleAccept} disabled={isLoading}>
                    Accept Invitation
                </Button>
            </Box>
        </StyledContainer>
    );
};

export default AcceptInvitePage;