import { createSlice } from '@reduxjs/toolkit';

const patientSlice = createSlice({
    name: 'patient',
    initialState: {
        patientId: null,
    },
    reducers: {
        setPatientId: (state, action) => {
            state.patientId = action.payload;
        },
    },
});

export const { setPatientId } = patientSlice.actions;

export default patientSlice.reducer;