import { Fragment, useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Button,
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Icon,
    useTheme,
    styled,
    Divider,
    Dialog,
    Link
} from "@mui/material";
import { useGetUserQuery } from "../../utils/redux/api/userApi";
import { useDispatch } from 'react-redux';
import { setPatientId } from "../../utils/redux/slice/patientSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getInitials } from "../../utils/helpers";
import Goals from "./Goals";
import { useGetPatientDetailQuery, useGetPatientMealPlansQuery, useGetPatientFoodLogQuery, useGetProgramGroupsQuery } from "../../utils/redux/api/programApi";
import FoodLogTable from "./FoodLogTable";
import PatientInfo from "./PatientInfo";
import MealPlan from "./PatientMealPlan";
import PatientChat from "./PatientChat";
import { useUpdatePatientDetailMutation } from "../../utils/redux/api/programApi";
import EditDietRestrictions from "../common/EditDietRestrictions";
import CloseIcon from '@mui/icons-material/Close';


const WrapperDiv = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    overflow: "hidden",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2),
        paddingRight: 0, // remove right padding on smaller screens
    },
}));

const ContentDiv = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    marginBottom: "1rem",
    marginTop: "1rem",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
}));

const MainContent = styled("div")(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: "0.8",
    margin: theme.spacing(2),
    gap: theme.spacing(1),
    overflowY: 'auto', // enable vertical scrolling if the content is too tall
}));

const Sidebar = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    flex: "0.2",
    height: "100%",
    margin: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    overflowY: "auto",
}));

const GoalSidebar = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    flex: "0.2",
    height: "100%",
    margin: theme.spacing(2),
    marginBottom: theme.spacing(4),
    overflowY: "auto",
}));

const VerticalActionsDiv = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
});

const PatientProfile = () => {
    const dispatch = useDispatch();
    const { data: user, isError, error, isLoading } = useGetUserQuery();
    const navigate = useNavigate();
    const theme = useTheme();
    const { id } = useParams();
    console.log(`Passed id: ${id}`)
    const location = useLocation();
    const { patient, goals, dietRestrictions } = location.state;
    const {
        data: programGroups,
        isError: isProgramGroupsError,
        error: programGroupsError,
    } = useGetProgramGroupsQuery(user?.programUser?.programId, { skip: !user });
    const { data: patientDetail, isloading: patientDetailIsLoading, isError: patientDetailIsError } = useGetPatientDetailQuery(id);
    const { data: patientMealPlans, isloading: patientMealPlansIsLoading, isError: patientMealPlansIsError } = useGetPatientMealPlansQuery(id);
    const { data: patientFoodLog, isloading: patientFoodLogIsLoading, isError: patientFoodLogIsError } = useGetPatientFoodLogQuery(id);
    const [editGoalsOpen, setEditGoalsOpen] = useState(false);
    const [updatePatientDetail, { isLoading: isUpdatingPatientDetail }] = useUpdatePatientDetailMutation();
    const [dietRestrictionsOpen, setDietRestrictionsOpen] = useState(false);
    const [foodLogOpen, setFoodLogOpen] = useState(false);
    const [mealPlansOpen, setMealPlansOpen] = useState(false);

    const handleOpenFoodLog = () => {
        setFoodLogOpen(true);
    };

    const handleCloseFoodLog = () => {
        setFoodLogOpen(false);
    };

    const handleOpenMealPlans = () => {
        setMealPlansOpen(true);
    }

    const handleCloseMealPlans = () => {
        setMealPlansOpen(false);
    }

    const handleEditDietRestrictionsOpen = () => {
        setDietRestrictionsOpen(true);
    };

    const handleEditDietRestrictionsClose = () => {
        setDietRestrictionsOpen(false);
    };


    if (patientDetail) {
        console.log("goals: " + patientDetail.goals)

    }


    // if (isLoading) {
    //     return <div>Loading...</div>; // Replace with your loading component or message
    // }

    // if (isError) {
    //     // Redirect the user or show an error message
    //     navigate("/error");
    // }

    const updateGoals = async (newGoals) => {
        try {
            const updateData = {
                program_patient_id: id,
                goals: newGoals,
            };
            await updatePatientDetail(updateData).unwrap();
            // handle success
        } catch (error) {
            // handle error
        }
    };

    const updateDietRestrictions = async (newDietRestrictions) => {
        try {
            const updateData = {
                program_patient_id: id,
                diet_restrictions: newDietRestrictions,
            };
            await updatePatientDetail(updateData).unwrap();
            // handle success
        } catch (error) {
            // handle error
        }
    };


    const handleEditGoalsOpen = () => {
        setEditGoalsOpen(true);
    };

    const handleEditGoalsClose = () => {
        setEditGoalsOpen(false);
    };



    useEffect(() => {
        if (id) {
            dispatch(setPatientId(id)); // dispatch the action to set the patient ID

        }
    }, [id, dispatch]);

    if (patientFoodLogIsLoading || patientMealPlansIsLoading || patientDetailIsLoading || isLoading) {
        return <div>Loading...</div>;
    }

    const updateProgramGroupId = async (newGroupId) => {
        try {
            const updateData = {
                program_patient_id: id,
                programGroupId: newGroupId,
            };
            await updatePatientDetail(updateData).unwrap();
            // handle success
        } catch (error) {
            // handle error
        }
    };

    return (
        <WrapperDiv>
            <ContentDiv>
                <GoalSidebar>
                    <Card variant="outlined">
                        <CardHeader
                            title="Patient Details"
                            action={
                                <VerticalActionsDiv>
                                    <Button onClick={handleEditGoalsOpen}>Edit Goals</Button>
                                    <Button onClick={handleEditDietRestrictionsOpen}>Edit Restrictions</Button>
                                    <EditDietRestrictions
                                        dietRestrictions={patientDetail?.dietRestrictions || []}
                                        onSave={updateDietRestrictions}
                                        open={dietRestrictionsOpen}
                                        handleClose={handleEditDietRestrictionsClose}
                                    />
                                </VerticalActionsDiv>
                            }
                        />
                        <CardContent>
                            <Typography variant="h6">Goals</Typography>
                            {patientDetail && patientDetail.goals && Object.entries(JSON.parse(patientDetail.goals)).map(([goalKey, goalValue]) => {
                                let operatorSymbol;
                                switch (goalValue.operator) {
                                    case "lte":
                                        operatorSymbol = "≤";
                                        break;
                                    case "gte":
                                        operatorSymbol = "≥";
                                        break;
                                    case "eq":
                                        operatorSymbol = "=";
                                        break;
                                    case "range":
                                        operatorSymbol = "Range";
                                        break;
                                    default:
                                        operatorSymbol = goalValue.operator;
                                }

                                const formattedGoalKey = goalKey
                                    .replace(/_/g, ' ')
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ');

                                return (
                                    <Typography variant="body1" key={goalKey}>
                                        {formattedGoalKey}: {operatorSymbol} {goalValue.operator === 'range' ? `${goalValue.minValue} - ${goalValue.maxValue}` : goalValue.value}
                                    </Typography>
                                );
                            })}
                            <Typography variant="h6">Diet Restrictions</Typography>
                            {patientDetail?.dietRestrictions && <Typography variant="body1">{patientDetail.dietRestrictions}</Typography>}
                        </CardContent>
                    </Card>
                    <Goals
                        open={editGoalsOpen}
                        handleClose={handleEditGoalsClose}
                        goals={patientDetail && patientDetail.goals ? JSON.parse(patientDetail.goals) : {}}
                        setGoals={updateGoals} // you need to define this function to update the patientDetail goals
                    />

                    <Divider />

                    <Card variant="outlined">
                        <CardHeader title="Group Details" />
                        <CardContent>
                            <Typography variant="h6">Goals</Typography>
                            {goals && Object.entries(goals).map(([goalKey, { operator, minValue, maxValue, value }]) => {
                                let operatorSymbol;
                                switch (operator) {
                                    case "lte":
                                        operatorSymbol = "≤";
                                        break;
                                    case "gte":
                                        operatorSymbol = "≥";
                                        break;
                                    case "eq":
                                        operatorSymbol = "=";
                                        break;
                                    case "range":
                                        operatorSymbol = "Range";
                                        break;
                                    default:
                                        operatorSymbol = operator;
                                }

                                const formattedGoalKey = goalKey
                                    .replace(/_/g, ' ')
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ');

                                return (
                                    <Typography variant="body1" key={goalKey}>
                                        {formattedGoalKey}: {operatorSymbol} {operator === 'range' ? `${minValue} - ${maxValue}` : value}
                                    </Typography>
                                );
                            })}
                            <Typography variant="h6">Diet Restrictions</Typography>

                            {dietRestrictions && <Typography variant="body1">{dietRestrictions}</Typography>}
                        </CardContent>
                    </Card>
                </GoalSidebar>
                <MainContent>
                    {patientDetail && programGroups && (
                        <PatientInfo patient={patient} currentGroup={patientDetail.programGroupId} programGroups={programGroups.groups} updateProgramGroupId={updateProgramGroupId}
                        />
                    )}
                    {patientFoodLogIsLoading ? (
                        <div>Loading...</div>
                    ) : patientFoodLogIsError ? (
                        <div>Error!</div>
                    ) : patientFoodLog ? (
                        <>
                            <FoodLogTable foodLog={patientFoodLog.log} onMaximize={handleOpenFoodLog} />
                            <Dialog
                                open={foodLogOpen}
                                onClose={handleCloseFoodLog}
                                fullWidth
                                maxWidth="md"
                            >
                                <IconButton onClick={handleCloseFoodLog} style={{ position: 'absolute', right: 0, top: 0 }}>
                                    <CloseIcon />
                                </IconButton>
                                <FoodLogTable foodLog={patientFoodLog.log} maximized={true} />
                            </Dialog>
                        </>
                    ) : (
                        <div>No food log data</div>
                    )}
                    {patientMealPlans && (
                        <>
                            <MealPlan mealPlans={patientMealPlans.mealPlans} onMaximize={handleOpenMealPlans} />
                            <Dialog
                                open={mealPlansOpen}
                                onClose={handleCloseMealPlans}
                                fullWidth
                                maxWidth="md"
                            >
                                <IconButton onClick={handleCloseMealPlans} style={{ position: 'absolute', right: 0, top: 0 }}>
                                    <CloseIcon />
                                </IconButton>
                                <MealPlan mealPlans={patientMealPlans.mealPlans} maximized={true} />
                            </Dialog>
                        </>
                    )}
                </MainContent>
                <Sidebar>
                    <PatientChat patientId={id} userId={user.id} />
                </Sidebar>
            </ContentDiv>
        </WrapperDiv>
    );
};

export default PatientProfile;