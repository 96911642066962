import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useState } from 'react';

const InvitePatientDialog = ({ groups, open, handleClose, handleInvite }) => {
    const [email, setEmail] = useState('');
    const [group, setGroup] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleGroupChange = (event) => {
        setGroup(event.target.value);
        console.log(group)

    };

    const handleInviteClick = () => {
        handleInvite(email, group);
        setEmail('');
        setGroup('');
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Invite New Patient</DialogTitle>
            <DialogContent>
                <TextField
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                    sx={{ my: '1rem' }}
                />
                <FormControl fullWidth>
                    <InputLabel id="group-select-label">Group</InputLabel>
                    <Select
                        labelId="group-select-label"
                        value={group}
                        onChange={handleGroupChange}
                        sx={{ mt: '0.5rem' }}
                    >
                        {groups.map((group) => (
                            <MenuItem key={group.id} value={group.id}>{group.restrictionType}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleInviteClick} color="primary">
                    Invite
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvitePatientDialog;