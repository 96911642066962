import { styled } from '@mui/material/styles';

const ScrollDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    overflowY: 'auto',
    scrollbarColor: '#e9c46a rgba(0,0,0,0.4)',
    alignItems: 'center',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 0.37rem rgba(0,0,0,0.4)',
        border: '1px solid #000',
        borderRadius: '0.28rem',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#e9c46a',
        borderRadius: '0.28rem',
    },
}));

export default ScrollDiv;