import { Card, CardContent, CardHeader, Typography, Avatar, CardActions, Select, MenuItem, InputLabel, FormControl } from '@mui/material'; import { useState } from 'react';
const PatientInfo = ({ patient, currentGroup, programGroups, updateProgramGroupId }) => {
    const [group, setGroup] = useState(currentGroup);

    const handleChange = (event) => {
        setGroup(event.target.value);
        updateProgramGroupId(event.target.value);
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar alt={patient.displayName} src={patient.profilePictureUrl} />
                }
                title="Patient Info"
                action={
                    <FormControl variant="standard" margin="dense">
                        <InputLabel id="group-label">Group</InputLabel>
                        <Select labelId="group-label" value={group} onChange={handleChange}>
                            {programGroups.map((group) => (
                                <MenuItem key={group.id} value={group.id}>
                                    {group.restrictionType}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    <strong>Name:</strong> {patient.displayName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <strong>Email:</strong> {patient.email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <strong>Patient ID:</strong> {patient.patientId}
                </Typography>
                {/* Add more fields as needed */}
            </CardContent>

        </Card>
    );
};

export default PatientInfo;