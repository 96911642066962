import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button } from '@mui/material';
import { useState } from 'react';

const AddProgramGroupDialog = ({ open, handleClose, programId, onSubmit }) => {
    const [restrictionType, setRestrictionType] = useState('');
    const [goals, setGoals] = useState('');
    const [dietRestrictions, setDietRestrictions] = useState('');

    const handleSave = async () => {
        const data = {
            programId: programId,
            restrictionType: restrictionType,
        };
        await onSubmit(data);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Program Group</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the form below.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    value={restrictionType}
                    onChange={(e) => setRestrictionType(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddProgramGroupDialog;