import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Typography, Button, styled } from "@mui/material";
import { useVerifyTokenMutation } from '../../utils/redux/api/userApi';

const WrapperDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const Verify = () => {
    const [verified, setVerified] = useState(false);
    const [queryParameters] = useSearchParams()
    const token = queryParameters.get('token')
    const [verifyToken, { isError, error, isSuccess }] = useVerifyTokenMutation();
    const navigate = useNavigate();

    useEffect(() => {
        const verify = async () => {
            if (token) {
                await verifyToken({ token });
                if (isSuccess) {
                    setVerified(true);
                }
            }
        }
        verify();
    }, [isSuccess, token, verifyToken]);

    if (verified) {
        return (
            <WrapperDiv>
                <Typography variant="h4">You have been verified.</Typography>
                <Typography variant="h6">Please login to continue.</Typography>
                <StyledButton variant="contained" color="primary" onClick={() => navigate('/login')}>
                    Login
                </StyledButton>
            </WrapperDiv>
        )
    }

    else {
        return (
            <WrapperDiv>
                <Typography variant="h4">Oops</Typography>
            </WrapperDiv>
        )
    }
};

export default Verify;