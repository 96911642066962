import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledFooter = styled('footer')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
    width: '100%',
    position: 'absolute',
    bottom: 0,
}));

const Footer = () => {
    return (
        <StyledFooter>
            <Typography variant='body2'>&copy; 2023 FoodFresh. All rights reserved.</Typography>
        </StyledFooter>
    )
}

export default Footer;