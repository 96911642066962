import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostLogoutMutation } from "../../utils/redux/api/userApi";
import { useDispatch } from 'react-redux';
import { api } from '../../utils/redux/api/base';
import { resetLogoutTrigger, setIntervalId } from '../redux/slice/inactivitySlice';

export const useLogout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [logout, { isLoading: isLoggingOut }] = usePostLogoutMutation();

    const handleLogout = useCallback(async () => {
        try {
            await logout();
            dispatch(api.util.resetApiState());
            dispatch(resetLogoutTrigger());
            dispatch(setIntervalId(null)); // Clear the interval ID in the state
            navigate("/login");
        } catch (error) {
            console.error(error);
        }
    }, [logout, dispatch, navigate]);

    return { handleLogout, isLoggingOut };
};