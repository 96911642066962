import { configureStore } from '@reduxjs/toolkit';
import { api } from './api/base';
import secureStorageMiddleware from './middleware/secureStorageMiddleware';
import refreshSessionMiddleware from './middleware/refreshSessionMiddleware';
import userApi from './api/userApi';
import themeReducer from './slice/themeSlice';
import notificationReducer from './slice/notificationSlice';
import { toggleMode } from './slice/themeSlice';
import patientReducer from './slice/patientSlice'; // import the patient slice
import conversationReducer from './slice/conversationSlice';
import inactivityReducer from './slice/inactivitySlice'; // import the inactivity slice

export const postLoginFulfilledMatcher = userApi.endpoints.postLogin.matchFulfilled;
export const postLogoutFulfilledMatch = userApi.endpoints.postLogout.matchFulfilled;

// const tokenConfig = {
//     storageKey: 'foodfreshCredentials',
//     getState: (state) => state.auth,
//     shouldSave: (action) =>
//         postLoginFulfilledMatcher(action),
//     shouldDelete: (action) => postLogoutFulfilledMatch(action)
// };

const themeConfig = {
    storageKey: 'theme',
    getState: (state) => state.theme,
    shouldSave: (action) => action.type === toggleMode.type,
};

export const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: {
        [api.reducerPath]: api.reducer,
        theme: themeReducer,
        notification: notificationReducer,
        patient: patientReducer,
        conversation: conversationReducer,
        inactivity: inactivityReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(api.middleware)
            .concat(refreshSessionMiddleware(postLoginFulfilledMatcher, postLogoutFulfilledMatch)) // Pass postLogoutFulfilledMatch as a parameter
            .concat(secureStorageMiddleware([themeConfig]))
});