export function getInitials(name) {
  const parts = name.split(' ');
  const firstInitial = parts[0][0];
  const lastInitial = parts.length > 1 ? parts[parts.length - 1][0] : '';
  return firstInitial + lastInitial;
}

export const formatTime = (timeInMilliseconds) => {
  const totalSeconds = Math.floor(timeInMilliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  if (minutes === 0) {
    return `${seconds} seconds`;
  } else {
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} minutes`;
  }
};