import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogActions, DialogContentText, Button } from '@mui/material';
import { closeDialog, resetTimer, checkInactivity } from '../../utils/redux/slice/inactivitySlice';
import { formatTime } from '../../utils/helpers';
import { totalInactivityTime } from '../../utils/staticData';

const InactivityLogoutDialog = ({ handleLogout }) => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.inactivity.dialogOpen);
    const lastActiveTime = useSelector((state) => state.inactivity.lastActiveTime);
    const [remainingTime, setRemainingTime] = useState(totalInactivityTime - (Date.now() - lastActiveTime));

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(totalInactivityTime - (Date.now() - lastActiveTime));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [lastActiveTime]);

    const handleContinueSession = () => {
        dispatch(resetTimer());
        dispatch(checkInactivity());
        dispatch(closeDialog());
    };

    const handleLogoutClick = () => {
        dispatch(closeDialog());
        handleLogout();
    }

    const handleClose = () => {
        dispatch(closeDialog());
    };


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle>Inactivity Alert</DialogTitle>
            <DialogContentText>
                You will be logged out in {formatTime(remainingTime)} seconds due to inactivity.
            </DialogContentText>
            <DialogActions>
                <Button onClick={handleContinueSession} color="primary">
                    Continue Session
                </Button>
                <Button onClick={handleLogoutClick} color="primary">
                    Logout
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InactivityLogoutDialog;