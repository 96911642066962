import { createAction, createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
    name: 'theme',
    initialState: { mode: 'dark' },
    reducers: {
        setInitialThemeMode: (state, action) => {
            state.mode = action.payload.mode;
        },
        setThemeMode: (state, action) => {
            state.mode = action.payload;
        },
        toggleMode: (state) => {
            return state.mode === 'light' ? { mode: 'dark' } : { mode: 'light' }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(hydrateThemeFromStorage, (state, action) => {
            state.mode = action.payload.mode;
        });
    },
});

export const hydrateThemeFromStorage = createAction('theme/hydrateFromStorage');
export const { toggleMode, setInitialThemeMode, setThemeMode } = themeSlice.actions;

export default themeSlice.reducer;