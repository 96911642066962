import React from 'react';
import { Outlet } from "react-router-dom";
import { styled } from '@mui/material/styles';
const FlexWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    width: '100%',
    height: '100%',
    bottom: '1.25rem'
}));

const WithoutNav = () => {
    return (
        <FlexWrapper>
            <Outlet />
        </FlexWrapper>
    )
}

export default WithoutNav;