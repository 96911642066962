import { api } from './base';

const recipeApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUserRecipes: build.query({
            query: () => ({ url: '/recipes/user', credentials: 'include' }),
            providesTags: ['Recipe']
        }),
        getNewRecipe: build.mutation({
            query: (data) => ({
                url: '/recipe/v2',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Recipe', 'User']
        }),
        getMealPlan: build.query({
            query: () => ('/recipes/meal-plan'),
            providesTags: ['MealPlan']
        })
    })
});

export const { useGetUserRecipesQuery, useGetNewRecipeMutation, useGetMealPlanQuery } = recipeApi

export default recipeApi;