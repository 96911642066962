import { Middleware } from '@reduxjs/toolkit';

const secureStorageMiddleware = (configs) => (storeAPI) => (next) => (action) => {
    const result = next(action);

    for (const config of configs) {
        if (config.shouldSave(action, storeAPI.getState())) {
            try {
                console.log('state: ', config.getState(storeAPI.getState()));
                localStorage.setItem(
                    config.storageKey,
                    JSON.stringify(config.getState(storeAPI.getState()))
                );
            } catch (error) {
                console.error('Failed to save data to local storage', error);
            }
        }

        if (config.shouldDelete && config.shouldDelete(action, storeAPI.getState())) {
            try {
                console.log('deleted state: ', config.getState(storeAPI.getState()))

                localStorage.removeItem(config.storageKey);
            } catch (error) {
                console.error('Failed to delete key from local storage', error);
            }
        }
    }

    return result;
};

export default secureStorageMiddleware;