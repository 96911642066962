import { useEffect, useState, useMemo } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import IngredientsPanel from './IngredientsPanel';
import RecipeViewer from './RecipeViewer';
import { useGetUserQuery } from '../utils/redux/api/userApi';
import { useGetUserRecipesQuery, useGetNewRecipeMutation } from '../utils/redux/api/recipeApi';


const WrapperDiv = styled('div')(({ theme }) => ({
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
}));

const HeaderDiv = styled('div')(({ theme }) => ({
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
        paddingTop: 0,
    },
}));

const RecipeSelectDiv = styled('div')(({ theme }) => ({
    marginLeft: '1rem',
    display: 'flex',
    justifySelf: 'flex-start',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
        width: '10rem',
        '& >*': {
            justifySelf: 'center',
            marginBottom: '1rem',
        }
    },
}));

const CreditDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifySelf: 'flex-end',
    height: 'auto',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'end',
        marginRight: '1rem',
        alignItems: 'center',
    },
}));

const ContentDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'inherit',
    marginLeft: '1rem',
    overflow: 'hidden',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
    },
    marginBottom: '5rem',
}));

const IngredientDiv = styled('div')(({ theme }) => ({
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '20rem',
    height: '100%',
    marginBottom: '4rem',
}));

const RecipeDiv = styled('div')(({ theme }) => ({
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    height: '100%',
}));

const LoadingDiv = styled('div')(({ theme }) => ({
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    alignItems: 'center',
}));

const Recipe = () => {
    const { data: user, isError, error } = useGetUserQuery();

    const [recipe, setRecipe] = useState(null);
    const [recipeLoading, setRecipeLoading] = useState(false);
    const [recipeError, setRecipeError] = useState(null);
    const { data: userRecipes, isError: userRecipesError, error: userRecipesErrorObj } = useGetUserRecipesQuery(null, { skip: !user });
    const [getNewRecipe] = useGetNewRecipeMutation();

    const [selectedRecipe, setSelectedRecipe] = useState();


    useEffect(() => {
        if (isError) {
            console.error(error);
        }
    }, [isError, error]);

    useEffect(() => {
        if (userRecipesError) {
            console.error(userRecipesErrorObj);
        }
    }, [userRecipesError, userRecipesErrorObj]);


    const submitIngredients = async (ingredients, dietaryOption = null, cuisineOption = null, allergiesOption = null) => {
        const recipeReq = {
            ingredients: ingredients,
        }
        if (dietaryOption) {
            recipeReq.dietary_option = dietaryOption
        }
        if (cuisineOption) {
            recipeReq.cuisine_option = cuisineOption
        }
        if (allergiesOption) {
            recipeReq.allergies_option = allergiesOption
        }
        setRecipeLoading(true);
        try {
            const data = await getNewRecipe(recipeReq).unwrap();
            setRecipe({ title: data.title, servings: data.servings, ingredients: data.ingredients, instructions: data.instructions, nutrition: data.nutrition, notes: data.notes });
            setRecipeLoading(false);
        } catch (error) {
            setRecipeError('Not Enough Credits.');
            setRecipeLoading(false);
        }
    };

    const LoggedInHeader = () => {
        if (user) {
            const creditStr = (user.credit !== 1) ? 'credits' : 'credit'
            return (
                <CreditDiv>
                    <Typography variant='h6'>{user.credits} {creditStr}</Typography>
                </CreditDiv>
            );
        }
        else {
            return (
                <CreditDiv>
                    <Typography variant='h6'>You need to login to use this feature.</Typography>
                </CreditDiv>
            )
        }
    }
    const RecipeDropdown = ({ options }) => {
        return (
            <RecipeSelectDiv>
                <Autocomplete sx={{ width: '20rem' }}
                    value={selectedRecipe}
                    options={options}
                    onChange={(e, newValue) => {
                        setSelectedRecipe(newValue);
                        setRecipe(null);
                        setRecipeError(null);
                    }}
                    getOptionLabel={(option) => (option.title ? option.title : '')}
                    renderInput={(params) => <TextField {...params} label='Recipe Book' variant='standard' />}
                />
            </RecipeSelectDiv>
        )

    }
    const IngredientRecipeView = () => {
        if (recipeLoading) {
            return (
                <LoadingDiv>
                    <Typography variant='h6'>Loading Recipe...</Typography>
                    <CircularProgress color="secondary" sx={{ mt: '1rem' }} />
                </LoadingDiv>
            )
        }
        if (recipe) {
            return (
                <RecipeDiv>
                    <RecipeViewer recipe={recipe} />
                </RecipeDiv>
            )
        } else if (selectedRecipe) {
            return (
                <RecipeDiv>
                    <RecipeViewer recipe={selectedRecipe} />
                </RecipeDiv>
            )


        } else if (recipeError) {
            return (
                <LoadingDiv>
                    <Typography variant='h6'>
                        {recipeError}
                    </Typography>
                </LoadingDiv>
            )
        }
        else {
            return (
                <IngredientDiv>
                    <IngredientsPanel handleSubmit={submitIngredients} user={user} />
                </IngredientDiv>
            )
        }
    };

    const onRecipeSelect = () => {

    }

    return (
        <WrapperDiv className='wrapper-div'>
            <HeaderDiv>
                {userRecipes &&
                    <RecipeDropdown options={userRecipes.recipes} />}
                <LoggedInHeader />
            </HeaderDiv>
            <ContentDiv>
                <Typography variant='h4' sx={{ textDecoration: 'underline' }}>Recipe Finder</Typography>
                <IngredientRecipeView />
            </ContentDiv>
        </WrapperDiv>
    );
};

export default Recipe;