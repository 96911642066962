import { useState } from 'react';
import { Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import background from '../resources/vegetables.jpg';
import { StyledButton, StyledHomeInput } from './StyledInputs';
import { addEmailToMailingList } from '../apis/general';
import { androidLink, iosLink } from '../utils/staticData';
import androidBadge from '../resources/google-play-badge.png';
import iosBadge from '../resources/play-store-badge.png';
import { useGetUserQuery } from '../utils/redux/api/userApi';

const WrapperDiv = styled('div')(({ theme }) => ({
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
        backgroundPosition: 'center',
    },
}));

const ContentDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'inherit',
    marginLeft: '1rem',
    overflow: 'hidden',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
    },
}));

const InputDiv = styled('div')(({ theme }) => ({
    justifyContent: 'center',
    display: 'flex',
    marginTop: '1rem',
    flexDirection: 'row',
    paddingLeft: '2rem',
    paddingRight: '1rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    width: '30rem',
    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const FormDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '1rem',
    backgroundColor: 'rgba(128,128,128, 0.2)',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const AppDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '4rem',
    width: 'auto',
    justifyContent: 'center',
    '& .gplay': {
        marginLeft: '1rem',
    },
    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const CheckboxDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '3rem',
    paddingRight: '2rem',
    paddingBottom: '1rem',
    width: '30rem',
    '& .MuiTypography-body1': {
        fontSize: '1rem',

    },
    '& .MuiCheckbox-root': {
        color: '#333',
        '&.Mui-checked': {
            color: '#2ecc71',
        },
    },

    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const LandingDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#f1f8f6',
}));

const LandingHeader = styled('header')(({ theme }) => ({
    textAlign: 'center',
    marginBottom: '2rem',
    '& h1': {
        fontSize: '3rem',
        color: '#2ecc71',
    },
    '& .MuiTypography-subtitle1': {
        fontSize: '1.5rem',
        color: '#333',
    }
}));

const MainDiv = styled('main')(({ theme }) => ({
    textAlign: 'center',
    marginBottom: '2rem',
    '& h2': {
        fontSize: '2rem',
        color: '#333',
    },
    '& .MuiTypography-body1': {
        fontSize: '1.2rem',
        color: '#777',
    },
    '& .get-started': {
        marginTop: '1rem',
        padding: '1rem 2rem',
        fontSize: '1.2rem',
        backgroundColor: '#2ecc71',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        animation: 'pulse 2s infinite',
    },
    '& .join-button': {
        backgroundColor: '#2ecc71',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    '& h4': {
        marginTop: '1rem',
        fontSize: '1.5rem',
        color: '#333',
        '&.not-joined': {
            cursor: 'pointer',
            textDecoration: 'underline',

        }
    },
}));


const isEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
}

const Home = () => {
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [message, setMessage] = useState('Join our mailing list!');
    const [error, setError] = useState('');
    const [joined, setJoined] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [subscribeOptions, setSubscribeOptions] = useState({ 'app': true, 'features': false, 'promos': false })
    const navigate = useNavigate();
    const { data: user, isError, error: userError } = useGetUserQuery();

    const handleOnClick = () => {
        if (user) {
            navigate('/recipe');
        }

        else {
            navigate('/login');
        }
    }

    const handleListClick = () => {
        if (!joined) {
            setShowInput(true);
        }
    }

    const handleJoin = async () => {
        if (!isValid) {
            setError('Please enter a valid email address');
        } else {
            const data = { email, ...subscribeOptions }
            const resp = await addEmailToMailingList(data)
            setEmail('');
            if (resp.ok) {
                setMessage('Thank you for joining our mailing list!')
            } else {
                setMessage('You have already joined. Thank you!')
            }
            setShowInput(false);
            setJoined(true);
        }
    }

    const handleChange = (e) => {
        const { value } = e.target;
        if (isEmail(value)) {
            setIsValid(true)
            setError('')
        } else {
            setIsValid(false)
        }
        setEmail(value);
    };

    const handleCheckbox = (e) => {
        setSubscribeOptions({ ...subscribeOptions, [e.target.value]: e.target.checked });
    }

    return (
        <LandingDiv>
            <LandingHeader>
                <Typography variant='h1'>FoodFresh</Typography>
                <Typography variant='subtitle1'>Simplify Healthy Eating</Typography>
            </LandingHeader>
            <MainDiv>
                <Typography variant='h2'>Discover a New Way to Eat</Typography>
                <Typography variant='body1'>Personalized recipes, nutrition tracking, and more.</Typography>
                <Button variant='contained' onClick={handleOnClick} className='get-started'>Get Started</Button>
                <Typography variant='h4' className={joined ? 'joined' : 'not-joined'} onClick={handleListClick}>{message}</Typography>
                {showInput &&
                    <FormDiv>
                        <InputDiv>
                            <StyledHomeInput fullWidth placeholder='Email' value={email} onChange={handleChange} />
                            <StyledButton className='join-button' onClick={handleJoin}>Join</StyledButton>

                        </InputDiv>
                        <CheckboxDiv>
                            <FormControlLabel labelPlacement='end' control={<Checkbox checked={subscribeOptions['app']} onChange={handleCheckbox} value='app' />} label="Recieve updates on our iOS and Android apps!" />
                            <FormControlLabel labelPlacement='end' control={<Checkbox checked={subscribeOptions['features']} onChange={handleCheckbox} value='features' />} label="Recieve updates on our upcoming features!" />
                            <FormControlLabel labelPlacement='end' control={<Checkbox checked={subscribeOptions['promos']} onChange={handleCheckbox} value='promos' />} label="Recieve updates on our upcoming ads and promotions!" />
                        </CheckboxDiv>
                    </FormDiv>
                }
                <AppDiv>
                    <Link to={iosLink} target="_blank">
                        <img src={iosBadge} alt='get on app store' height='40' />
                    </Link>
                    <Link to={androidLink} target="_blank" className='gplay'>
                        <img src={androidBadge} alt='get on google play' height='40' />
                    </Link>

                </AppDiv>
            </MainDiv>
            <style>
                {`
          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.2);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
            </style>
        </LandingDiv>
    );
};

export default Home;

// <ContentDiv className='content'>
// {message === '' ?
//     <>
//         <Typography variant='h4'>Join our mailing list to be notified when our mobile app is available!</Typography>
// <InputDiv>
//     <StyledInput fullWidth placeholder='Email' value={email} onChange={handleChange} />
//     <StyledButton onClick={handleJoin}>Join</StyledButton>
// </InputDiv>
//         <Typography variant='h6' bgcolor='GrayText' sx={{ mt: '1rem' }}>{error}</Typography>
//     </> :
//     <Typography variant='h4' sx={{ mt: '5rem' }}>{message}</Typography>}
// </ContentDiv>