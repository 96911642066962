import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  styled,
  Typography,
  IconButton,
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RecipeDialog from '../common/RecipeDialog';

const WrapperDiv = styled('div')(({ theme }) => ({
  display: "flex",
  flex: 1,
  height: '20rem',
  flexDirection: "column",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-root': {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  '& .MuiDataGrid-row': {
    '&.even': {
      backgroundColor: theme.palette.action.selected,
    },
    '&.odd': {
      backgroundColor: theme.palette.background.paper,
    }
  },
}));

const TitleDiv = styled('div')(({ theme, maximized }) => ({
  display: 'flex',
  justifyContent: maximized ? 'center' : 'space-between',
  alignItems: 'center',
}));

const FoodLogTable = ({ foodLog, onMaximize, maximized }) => {
  const [open, setOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const handleClickOpen = (recipe) => {
    setSelectedRecipe(recipe);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!foodLog) {
    return null;
  }

  const columns = [
    {
      field: 'dateUsed',
      headerName: 'Date',

      flex: 0.6,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      }
    },
    { field: 'mealType', headerName: 'Meal Type', flex: 0.5 },
    {
      field: 'timeOfDay',
      headerName: 'Time of Day',
      flex: 0.5,
      valueFormatter: (params) => {
        if (!params.value) {
          return 'No time provided';
        }
        const timeParts = params.value.split(':');
        if (timeParts.length < 2) {
          return 'Invalid time format';
        }
        const date = new Date();
        date.setHours(timeParts[0]);
        date.setMinutes(timeParts[1]);
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
      }
    },
    {
      field: 'recipe', headerName: 'Recipe', flex: 1, renderCell: (params) => (
        params.value ? (
          <Button color="inherit" onClick={() => handleClickOpen(params.value)} style={{ textDecoration: 'underline' }}>
            {params.value.title}
          </Button>
        ) : (
          "No recipe"
        )
      )
    },
    {
      field: 'meal', headerName: 'Meal', flex: 1, valueGetter: (params) => (
        params.value ? params.value.name : "No meal"
      )
    }
  ];

  return (
    <WrapperDiv>
      <TitleDiv maximized={maximized}>

        {!maximized && (
          <div></div>
        )}
        <Typography variant="h4" gutterBottom alignSelf={'center'}>
          Food Log
        </Typography>
        {!maximized && (
          <IconButton onClick={onMaximize}>
            <FullscreenIcon />
          </IconButton>
        )}
      </TitleDiv>
      <StyledDataGrid
        rows={foodLog}
        columns={columns}
        pageSize={5}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
      <RecipeDialog open={open} handleClose={handleClose} recipe={selectedRecipe} />
    </WrapperDiv>
  );
};

export default FoodLogTable;