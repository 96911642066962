import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button } from '@mui/material';
import { useState } from 'react';

const AddProgramStaffDialog = ({ open, handleClose, programId, onAdd }) => {
    const [staffEmail, setStaffEmail] = useState('');

    const handleSave = async () => {
        const data = {
            programId: programId,
            staffEmail: staffEmail,
            userType: 'staff',
            invitationKeyId: null,
        };
        await onAdd(data);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Program Staff</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the form below.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Staff Email"
                    type="email"
                    fullWidth
                    value={staffEmail}
                    onChange={(e) => setStaffEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddProgramStaffDialog;