import { Input, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledInput = styled(Input)(({ theme }) => ({
    marginRight: '1rem',
    ':after': {
        borderBottomColor: theme.palette.optional.main,
    },
}));

export const StyledHomeInput = styled(Input)(({ theme }) => ({
    marginRight: '1rem',
    color: '#000000',
    ':before': {
        borderBottomColor: '#000000',
    },
    ':after': {
        borderBottomColor: '#2ecc71',
    },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.optional.main,
    '&.MuiButton-contained': {
        color: '#FFFFFF',
        backgroundColor: theme.palette.optional.main,
        '&.Mui-disabled': {
            backgroundColor: 'grey',
            pointerEvents: 'auto',
        },
    },

}));

export const StyledTextfield = styled(TextField)(({ theme }) => ({
    marginRight: '1rem',
}));