import { useState, useRef, useEffect, Fragment } from 'react';
import { useGetUserConversationsQuery, usePostNewConversationMutation } from '../../utils/redux/api/messagingApi';
import { getWebsocketManager } from '../../utils/redux/api/base';
import { TextField, Button, Typography, CircularProgress, styled } from '@mui/material';
import ChatWindow from '../common/ChatWIndow';
import NewConversationDialog from '../common/NewConversationDialog';


const WrapperDiv = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100%",
    alignItems: "center",
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
}));

const ChatWindowContainer = styled('div')(({ theme }) => ({
    flex: 0.33, // Take up 1/3 of the height
    // flex: 0.5, // Or take up 1/2 of the height
    width: '100%',
    position: 'relative',
    zIndex: 1,
}));

const ContentDiv = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

const ActiveContentDiv = styled(ContentDiv)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

const InactiveContentDiv = styled(ContentDiv)(({ theme }) => ({
    fontSize: '0.8rem',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));


const ConversationsDiv = styled('div')(({ theme }) => ({
    flex: 1, // Take up the remaining space
    width: "100%",
    padding: theme.spacing(1),
    overflowY: 'auto', // Add a scrollbar if the content overflows
}));

const NewConversationButton = styled(Button)(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));


const PatientChat = ({ patientId, userId }) => {
    const { data: conversations, isLoading, isError, error } = useGetUserConversationsQuery();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [activeConversation, setActiveConversation] = useState(null);
    const messageHistoryRef = useRef(null);
    const [postNewConversation, { isLoading: isCreatingConversation }] = usePostNewConversationMutation();

    const handleSendMessage = () => {
        if (message.trim() !== '') {
            const websocket = getWebsocketManager();
            websocket.sendMessage('NEW_MESSAGE', { conversation_id: activeConversation.id, text: message });
            console.log('sent message: ', { conversation_id: activeConversation.id, text: message });
            setMessage('');
        }
    };

    useEffect(() => {
        if (isError) {
            console.error('Error fetching conversations:', error);
        }
    }, [isError, error]);

    useEffect(() => {
        if (messageHistoryRef.current) {
            messageHistoryRef.current.scrollTop = messageHistoryRef.current.scrollHeight;
        }
    }, [activeConversation]);

    useEffect(() => {
        if (activeConversation) {
            const updatedConversation = conversations.conversations.find(convo => convo.id === activeConversation.id);
            if (updatedConversation) {
                setActiveConversation(updatedConversation);
            }
        }
    }, [conversations, activeConversation]);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleCreateConversation = async (conversation) => {
        try {
            if (!conversation.participants.includes(userId)) {
                // Add patient_id to the participants
                conversation.participants.push(userId);
            }
            if (!conversation.participants.includes(patientId)) {
                // Add patient_id to the participants
                conversation.participants.push(patientId);
            }
            await postNewConversation(conversation);
            // handle success
        } catch (error) {
            // handle error
        }
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    if (!conversations) {
        return (<p>no conversations</p>)
    }

    return (
        <WrapperDiv>
            <NewConversationButton variant="contained" color="primary" onClick={handleOpenDialog}>
                Create New Conversation
            </NewConversationButton>
            <NewConversationDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                onCreate={handleCreateConversation}
            />
            <ConversationsDiv>

                {conversations.conversations
                    .filter(conversation =>
                        conversation.participants && conversation.participants.some(participant => participant.userId === patientId)
                    )
                    .map((conversation) => {
                        const lastMessage = conversation.messages[conversation.messages.length - 1];
                        const isSelected = activeConversation && activeConversation.id === conversation.id;
                        const Content = isSelected ? ActiveContentDiv : activeConversation ? InactiveContentDiv : ContentDiv;
                        return (
                            <Content key={conversation.id} onClick={() => setActiveConversation(conversation)} style={{ cursor: 'pointer' }}>
                                <Typography variant="h6">{conversation.subject}</Typography>
                                {!isSelected && !activeConversation && (
                                    <Typography variant="body1">
                                        {lastMessage.sender || 'Unknown'}: {lastMessage.text}
                                    </Typography>
                                )}
                            </Content>
                        );
                    })}
            </ConversationsDiv>
            {activeConversation && (
                <ChatWindowContainer>
                    <ChatWindow
                        activeConversation={activeConversation}
                        message={message}
                        setMessage={setMessage}
                        handleSendMessage={handleSendMessage}
                        setActiveConversation={setActiveConversation}
                    />
                </ChatWindowContainer>
            )}
        </WrapperDiv>
    );
};

export default PatientChat;