import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api/base';
import { totalInactivityTime } from '../../staticData';

const initialState = {
    lastActiveTime: Date.now(),
    dialogOpen: false,
    triggerLogout: false,
    intervalId: null,
};

const inactivitySlice = createSlice({
    name: 'inactivity',
    initialState,
    reducers: {
        resetTimer: (state) => {
            state.lastActiveTime = Date.now();
        },
        openDialog: (state) => {
            state.dialogOpen = true;
        },
        closeDialog: (state) => {
            state.dialogOpen = false;
        },
        triggerLogout: (state) => {
            state.triggerLogout = true;
            clearInterval(state.intervalId); // Clear the interval here
            state.intervalId = null; // Set the interval ID to null
        },
        resetLogoutTrigger: (state) => {
            state.triggerLogout = false;
            if (state.intervalId) {
                clearInterval(state.intervalId);
                state.intervalId = null;
            }

        },
        setIntervalId: (state, action) => {
            state.intervalId = action.payload; // Store the interval ID in the state
        },
    },
});

export const { resetTimer, openDialog, closeDialog, triggerLogout, resetLogoutTrigger, setIntervalId } = inactivitySlice.actions;
// TODO: Ensure this is not reset by a refresh. 
export const checkInactivity = createAsyncThunk(
    'inactivity/checkInactivity',
    async (_, { dispatch, getState }) => {
        if (getState().inactivity.intervalId) {
            // An interval is already running, so return early without starting a new interval
            return;
        }

        const checkInterval = setInterval(() => {
            const state = getState(); // Get the latest state
            const elapsed = Date.now() - state.inactivity.lastActiveTime;
            const remainingTime = totalInactivityTime - elapsed;

            if (remainingTime <= 2 * 60 * 1000 && !state.inactivity.dialogOpen) { // Open dialog when there are 2 minutes left
                dispatch(openDialog());
            }
            if (remainingTime <= 0 && !state.inactivity.triggerLogout) { // Logout when the timer hits 0
                dispatch(triggerLogout());
                clearInterval(checkInterval);
            }
        }, 1000); // check every second

        dispatch(setIntervalId(checkInterval)); // Dispatch the setIntervalId action with the interval ID
    }
);

export default inactivitySlice.reducer;