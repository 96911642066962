import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { StyledButton, StyledInput } from '../StyledInputs';
import { usePostRegisterMutation, useRequestVerifyTokenMutation } from '../../utils/redux/api/userApi';

const WrapperDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: '1rem',
    alignItems: 'center',
}));

const BoxDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '30rem',
}));

const HeaderDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
}));

const ContentDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
}));

const TextDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    marginBottom: '1rem',
    marginTop: '1rem',
    '& form': {
        '& >*:not(:last-child)': {
            marginBottom: '1rem',
        }
    }
}));

const ButtonDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end'
}));



const Register = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [age, setAge] = useState('');
    const [sex, setSex] = useState('');
    const [budget, setBudget] = useState('');
    const [householdSize, setHouseholdSize] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [sent, setSent] = useState(false);
    const [postRegister, { isError, error, isSuccess }] = usePostRegisterMutation();
    const [requestVerifyToken, { isError: isTokenError, error: requestTokenError, isSuccess: isRequestTokenSuccess }] = useRequestVerifyTokenMutation();

    const clearFields = () => {
        setEmail('');
        setPassword('');
        setAge('');
        setErrorMessage(null);
        navigate(-1);
    };





    const handleRegister = async () => {
        try {
            await postRegister({ email, password, age, sex, budget, household_size: householdSize });
        } catch (err) {
            setErrorMessage(err);
        }
    };

    const handleChange = (event) => {
        setSex(event.target.value);
    };

    useEffect(() => {
        const handleRequestToken = async () => {
            const result = await requestVerifyToken({ email }).unwrap();
        };
        if (isSuccess) {
            handleRequestToken();
            setSubmitted(true);
        }
    }, [email, isSuccess, requestVerifyToken]);

    useEffect(() => {
        if (isError) {
            setErrorMessage(error);
        }

        if (isRequestTokenSuccess) {
            setSent(true);
        } else if (isTokenError) {
            setErrorMessage(requestTokenError);
        }
    }, [isError, error, isRequestTokenSuccess, isTokenError, requestTokenError]);


    if (submitted) {
        return (<WrapperDiv><Typography>Please check your email and click the link to verify.</Typography></WrapperDiv>)
    }

    return (
        <WrapperDiv>
            <BoxDiv>
                <HeaderDiv>
                    <Typography variant='h4'>Register</Typography>
                </HeaderDiv>
                <ContentDiv>
                    <TextDiv>
                        <form id="login-form" onSubmit={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleRegister();
                        }}>
                            <StyledInput
                                fullWidth
                                placeholder='Email'
                                label="Email"
                                value={email}
                                type="email"
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                            />
                            <StyledInput
                                fullWidth
                                placeholder='Password'
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                            />
                            <StyledInput
                                fullWidth
                                placeholder='Birth month/year (01/1993)'
                                label="Birth month/year"
                                value={age}
                                type="month"
                                onChange={(e) => {
                                    setAge(e.target.value)
                                }}
                            />
                            <StyledInput
                                fullWidth
                                placeholder='Monthly Grocery Budget (0 if there is none or is unknown)'
                                label="Budget"
                                value={budget}
                                type="number"
                                onChange={(e) => {
                                    setBudget(e.target.value)
                                }}
                            />
                            <StyledInput
                                fullWidth
                                placeholder='Number of people in household?'
                                label="Household Size"
                                value={householdSize}
                                type="number"
                                onChange={(e) => {
                                    setHouseholdSize(e.target.value)
                                }}
                            />
                            <Select
                                value={sex}
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem value={''}><em>Select Sex</em></MenuItem>
                                <MenuItem value={"male"}>Male</MenuItem>
                                <MenuItem value={"female"}>Female</MenuItem>
                                <MenuItem value={"other"}>Other</MenuItem>
                            </Select>
                        </form>
                    </TextDiv>
                    {errorMessage &&
                        <Typography variant='body'>{errorMessage}</Typography>}
                </ContentDiv>
                <ButtonDiv>
                    <StyledButton onClick={clearFields}>Cancel</StyledButton>
                    <StyledButton type="submit" form="login-form">
                        Register
                    </StyledButton>
                </ButtonDiv>
            </BoxDiv>
        </WrapperDiv>
    );
};

export default Register;
