import { createSlice } from '@reduxjs/toolkit';

const conversationSlice = createSlice({
    name: 'conversation',
    initialState: {
        open: false,
        data: null,
    },
    reducers: {
        openDialog: (state, action) => {
            state.open = true;
            state.data = action.payload;
        },
        closeDialog: (state) => {
            state.open = false;
            state.data = null;
        },
        updateData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { openDialog, closeDialog, updateData } = conversationSlice.actions;

export default conversationSlice.reducer;