import { useEffect, useState, Fragment, useCallback, useMemo } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  useGetUserQuery,
  usePostLogoutMutation,
} from "../../utils/redux/api/userApi";
import ThemeToggleButton from "./ToggleThemeButton";
import InactivityLogoutDialog from "../common/InactivityLogoutDialog";
import { api, initializeWebsocket } from '../../utils/redux/api/base';
import { useDispatch } from 'react-redux';
import { checkInactivity } from "../../utils/redux/slice/inactivitySlice";
import { useLogout } from "../../utils/hooks/useLogout";
import { useAutoLogout } from "../../utils/hooks/useAutoLogout";

const NavWrapper = styled("div")(({ theme }) => ({
  flexGrow: 1,
  "& .MuiAppBar-root": {
    backgroundColor: theme.palette.primary.main, // Use primary color from your theme
  },
  "& .MuiTypography-root": {
    color: theme.palette.text.primary,
    textDecoration: "none", // Remove text decoration to make it look more professional
    "&:hover": {
      textDecoration: "underline", // Add underline on hover for better interactivity
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // Slightly larger font for better readability
    },
  },
  "& .MuiToolbar-root": {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2), // Add right padding for symmetry
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiMenu-paper": {
    backgroundColor: theme.palette.optional.background,
  },
}));

const LinkWrapper = styled("div")(({ theme }) => ({
  "& .MuiTypography-root": {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  "& .MuiToolbar-root": {
    paddingLeft: "1rem",
  },
}));

const ButtonDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  marginLeft: "auto",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textDecoration: "none", // Remove the underline from links for a cleaner look
  "&:hover": {
    textDecoration: "underline", // Add underline on hover for better interactivity
  },
  padding: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    "&:not(.first)": {
      marginLeft: "1rem",
    },
  },
}));

const AuthDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& .MuiIconButton-root": {
    color: theme.palette.primary.contrastText,

  },
  "& .MuiTypography-root": {
    marginRight: theme.spacing(1), // Add space between text and icon
    color: theme.palette.primary.contrastText
  },
}));

const TopNav = () => {
  useAutoLogout();
  const { handleLogout, isLoggingOut } = useLogout();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { data: user, isError, error, isLoading } = useGetUserQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const pathName = useMemo(() => {
    const path = location.pathname;
    const formattedPath =
      path === "/" ? "Home" : path[1].toUpperCase() + path.slice(2);
    return formattedPath;
  }, [location]);

  useEffect(() => {
    if (isError) {
      console.error(`Error: ${error.status || error}`);
    }
  }, [isError, error]);

  useEffect(() => {
    dispatch(checkInactivity());
  }, [dispatch]);

  useEffect(() => {
    if (user)
      // Initialize WebSocket connection
      initializeWebsocket();
  }, [user]);

  const open = Boolean(anchorEl);

  const handleDialogLogout = async () => {
    await handleLogout();
  }

  if (!user && !isLoading) {
    console.log(pathName);
    if (!pathName.toLowerCase().includes("password")) {
      navigate("/login", { state: { from: location } });
    }
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const AuthButton = () => {
    if (user) {
      return (
        <AuthDiv>
          <Typography color={theme.palette.primary.contrastText}>
            Hello, <strong>{user.email.split("@")[0]}</strong>
          </Typography>
          <IconButton onClick={handleLogout} disabled={isLoggingOut}>
            <LogoutIcon />
          </IconButton>
        </AuthDiv>
      );
    } else {
      return (
        <AuthDiv>
          <Typography variant="body" style={{ marginRight: "0.5rem" }}>
            <StyledLink to="/register">Register</StyledLink>
          </Typography>
          <IconButton component={StyledLink} to="/login">
            <LoginIcon />
          </IconButton>
        </AuthDiv>
      );
    }
  };

  const NavLinks = () => {
    if (isMobile) {
      return (
        <Fragment>
          <IconButton onClick={handleOpenMenu} id="nav-menu-button">
            <MenuIcon />
          </IconButton>
          <Menu anchorEL={anchorEl} open={open} onClose={handleCloseMenu}>
            <MenuItem>
              <StyledLink to="/" className="first" onClick={handleCloseMenu}>
                Home
              </StyledLink>
            </MenuItem>
            <MenuItem>
              <StyledLink to="/recipe" onClick={handleCloseMenu}>
                Recipe
              </StyledLink>
            </MenuItem>
            {user &&
              user.programUser &&
              user.programUser.userType !== "patient" && (
                <MenuItem>
                  <StyledLink to="/program" onClick={handleCloseMenu}>
                    Program
                  </StyledLink>
                </MenuItem>
              )}
            <MenuItem>
              <StyledLink to="/about" onClick={handleCloseMenu}>
                About Us
              </StyledLink>
            </MenuItem>
            <MenuItem>
              <StyledLink to="/contact" onClick={handleCloseMenu}>
                Contact Us
              </StyledLink>
            </MenuItem>
          </Menu>
          <Typography variant="h1" onClick={handleCloseMenu}>
            {pathName}
          </Typography>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Typography
            variant="h6"
            sx={{ mr: "0.5rem", color: theme.palette.text.primary }}
          >
            <StyledLink to="/" className="first">
              Home
            </StyledLink>
          </Typography>
          {user &&
            user.programUser &&
            user.programUser.userType !== "patient" && (
              <Typography
                variant="h6"
                sx={{ mr: "0.5rem", color: theme.palette.text.primary }}
              >
                <StyledLink to="/program">Program</StyledLink>
              </Typography>
            )}
          <Typography
            variant="h6"
            sx={{ mr: "0.5rem", color: theme.palette.text.primary }}
          >
            <StyledLink to="/recipe">Recipe</StyledLink>
          </Typography>
          <Typography
            variant="h6"
            sx={{ mr: "0.5rem", color: theme.palette.text.primary }}
          >
            <StyledLink to="/about">About Us</StyledLink>
          </Typography>
          <Typography
            variant="h6"
            sx={{ mr: "0.5rem", color: theme.palette.text.primary }}
          >
            <StyledLink to="/contact">Contact Us</StyledLink>
          </Typography>
        </Fragment>
      );
    }
  };

  return (
    <NavWrapper>
      <AppBar
        color="primary"
        enableColorOnDark
        sx={{
          mb: 0,
          boxShadow: `0px 1px 2px -1px ${theme.palette.text.primary}, 0px 2px 3px 0px ${theme.palette.text.primary}, 0px 1px 5px 0px ${theme.palette.text.primary}`,
        }}
      >
        <Toolbar>
          <NavLinks />
          <ButtonDiv>
            <ThemeToggleButton />
            <AuthButton />
          </ButtonDiv>
        </Toolbar>
      </AppBar>
      <InactivityLogoutDialog handleLogout={handleDialogLogout} />
    </NavWrapper>
  );
};

// <AppBar color="primary" enableColorOnDark sx={{ mb: 0 }}></AppBar>

export default TopNav;
