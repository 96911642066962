import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Typography, TextField, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useResetPasswordMutation } from '../../utils/redux/api/userApi';
import { useNavigate } from 'react-router-dom';

const WrapperDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px', // Set a maximum width
    height: '100%',
    marginTop: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto', // Center the div
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const PasswordReset = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [message, setMessage] = useState('');
    const [resetStatus, setResetStatus] = useState(false);
    const [queryParameters] = useSearchParams();
    const token = queryParameters.get('token');
    const [resetPassword, { isSuccess }] = useResetPasswordMutation();

    const handleReset = async () => {
        if (password !== confirmPassword) {
            setMessage("Passwords don't match");
        }
        else {
            if (token) {
                await resetPassword({ token, password });
            }
        }
    }

    useEffect(() => {
        if (isSuccess) {
            setResetStatus(true);
        }
    }, [isSuccess]);

    if (resetStatus) {
        return (
            <WrapperDiv>
                <Typography variant="h4" style={{ textAlign: 'center' }}>Password Reset Successful</Typography>
                <Typography variant="h6" style={{ textAlign: 'center' }}>Your password has been successfully reset. Please login to continue.</Typography>

                <StyledButton variant="contained" color="primary" style={{ marginTop: '1rem' }} onClick={() => navigate('/login')}>
                    Login
                </StyledButton>
            </WrapperDiv>
        )
    }

    return (
        <WrapperDiv>
            <Typography variant="h4">Reset Password</Typography>
            <Typography variant="h6">Please enter your new password.</Typography>
            <TextField
                fullWidth
                placeholder='Password'
                label="Password"
                type="password"
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                fullWidth
                placeholder='Confirm Password'
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                    setConfirmPassword(e.target.value)
                }}
                style={{ marginTop: '1rem' }}
            />
            {message &&
                <Typography variant="subtitle1" color="error" style={{ marginTop: '1rem' }}>{message}</Typography>
            }
            <StyledButton variant="contained" color="primary" onClick={handleReset} style={{ marginTop: '1rem' }}>
                Submit
            </StyledButton>
        </WrapperDiv>
    )
}

export default PasswordReset;