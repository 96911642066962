import { useRef, useEffect } from 'react';
import { LinearWebsocketRetryPolicy } from './LinearWebsocketRetryPolicy';
import { getMsgCallbackMap, msgActions } from './websocketConstants';

const wsUrl = process.env.REACT_APP_WS_URL // || 'ws://192.168.1.175:8000/api/ws'
const WSInterval = 300000; // Every 5 minutes

const DefaultConfig = {
    pathname: '/api/ws',
    logPrefix: 'foodfresh'
};


export class WebsocketManager {
    constructor(params) {
        const { wsParams = DefaultConfig, onWSState, accessToken } = params ?? {};
        this.onWSState = onWSState;
        const mergedWSParams = { ...DefaultConfig, ...wsParams };

        this.uri = `${wsUrl}`;
        console.log(this.uri);
        this.retryPolicy = new LinearWebsocketRetryPolicy(10);
        this.accessToken = accessToken;
        this.msgCallbackMap = getMsgCallbackMap();
        this.logPrefix = mergedWSParams.logPrefix;

        this.ws = this.newWebsocket();
        this.subscriptionCounter = 1;
    }

    newWebsocket = () => {
        console.log('uri:', this.uri);
        console.log('accessToken:', this.accessToken);
        console.log(`${this.uri}?token=${this.accessToken}`);
        const socket = new WebSocket(`${this.uri}`, null);
        socket.onopen = () => {
            socket.send(JSON.stringify({ type: 'authenticate', message: 'Auth Message' }))
            setTimeout(() => {
                if (socket.readyState === socket.OPEN) {
                    this.onWSState('open');
                    this.retryPolicy.reset();
                }
            }, 25);
        };

        socket.onclose = () => {
            this.onWSState('closed');
            this.retryWebsocket();
        };

        socket.onerror = (event) => this.onWSState('error', event);

        socket.onmessage = this.onMessage;
        this.keepWSOpen();
        return socket;
    };

    open = () => {
        this.ws = this.newWebsocket();
    };

    retryWebsocket = () => {
        if (this.retryPolicy.exceededMax()) {
            console.log(
                `${this.logPrefix
                } giving up reconnection after ${this.retryPolicy.getRetries()} attempts.`
            );
            return;
        }

        const delay = this.retryPolicy.wait();
        console.log(
            `${this.logPrefix} attempting reconnection in ${delay / 1000} seconds.`
        );
        setTimeout(this.open, delay);
    };

    destructor = () => {
        this.ws.close();
    };


    keepWSOpen = () => {
        setTimeout(() => {
            if (this.ws.readyState === this.ws.OPEN) {
                this.ws.send('');
                this.keepWSOpen();
            }
        }, WSInterval);
    };

    registerHandler = (messageType, func) => {
        if (!this.msgCallbackMap[messageType]) {
            this.msgCallbackMap[messageType] = [];
        }
        const id = this.subscriptionCounter + 1;
        this.msgCallbackMap[messageType].push({ id, func });
        this.subscriptionCounter = id;
        return id;
    };

    unregisterHandler = (messageType, id) => {
        if (
            this.msgCallbackMap[messageType] &&
            this.msgCallbackMap[messageType].length > 0
        ) {
            this.msgCallbackMap[messageType] = this.msgCallbackMap[
                messageType
            ].filter((element) => element.id !== id);
        }
    };

    updateHandler = (messageType, id, newFunc) => {
        if (id === 0) return;
        if (this.msgCallbackMap[messageType]) {
            const index = this.msgCallbackMap[messageType].findIndex(
                (element) => element.id === id
            );
            if (index !== -1) {
                this.msgCallbackMap[messageType][index].func = newFunc;
            }
        }
    };

    updateAccessToken = (accessToken) => {
        if (accessToken) {
            this.accessToken = accessToken
            this.ws.send(JSON.stringify({ type: 'authenticate', token: accessToken }))
        }
    }

    closeConnection = () => {
        this.ws.close();
    }

    onMessage = async (msgEvent) => {
        // Parse message
        let msg = null;
        try {
            // First parse
            const parsedString = JSON.parse(msgEvent.data);

            // Second parse
            msg = JSON.parse(parsedString);
            console.log('Parsed msg:', msg);
        } catch (err) {
            console.log(`Bad ${this.logPrefix} WS message:`, err);
        }
        if (!msg) {
            return;
        }

        if (
            this.msgCallbackMap[msg.action] &&
            this.msgCallbackMap[msg.action].length > 0
        ) {
            console.log('maybe not');
            this.msgCallbackMap[msg.action].forEach((entry) => {
                console.log('maybe we are here?');
                entry.func(msg?.data);
            });
        }
    };

    sendMessage = (messageType, messageData) => {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            const message = {
                type: messageType,
                data: messageData,
            };
            this.ws.send(JSON.stringify(message));
        } else {
            console.error("WebSocket connection is not open");
        }
    }
}