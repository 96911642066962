import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import background from '../resources/platesHeaderNoBG.png';


const WrapperDiv = styled('div')(({ theme }) => ({
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: theme.palette.background,
}));

const HeaderDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    width: '100%',
    justifyContent: 'center',
    '& .MuiTypography-root': {
        color: '#e9c46a',
        background: '#000000',
        paddingLeft: '8.7rem',
        paddingRight: '8.7rem',
        backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiTypography-root': {
            paddingLeft: '1rem',
            paddingRight: '1rem',

        },
    },

    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
}));

const AboutUs = () => {

    return (
        <WrapperDiv className='about'>
            <HeaderDiv className='header'>
                <Typography variant='h2' sx={{ textDecoration: 'underline' }}>About Us</Typography>
            </HeaderDiv>
        </WrapperDiv>
    )
}

export default AboutUs;