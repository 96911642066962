import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLogout } from './useLogout';
import { closeDialog } from '../redux/slice/inactivitySlice';

export const useAutoLogout = () => {
    const { handleLogout, isLoggingOut } = useLogout();
    const dispatch = useDispatch();
    const logoutTriggered = useSelector(state => state.inactivity.triggerLogout);

    useEffect(() => {
        if (logoutTriggered && !isLoggingOut) {
            console.log('logout triggered')
            handleLogout();
            dispatch(closeDialog());
        }
    }, [logoutTriggered, handleLogout, isLoggingOut, dispatch]);
};