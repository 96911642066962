import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';

const NewConversationDialog = ({ open, onClose, onCreate, availableParticipants, availableGroups, initialSubject }) => {
    const [subject, setSubject] = useState(initialSubject);
    const [participants, setParticipants] = useState([]);
    const [groupType, setGroupType] = useState(null);
    const [text, setText] = useState('');

    const handleCreate = () => {
        onCreate({ subject, participants, groupType, text });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Conversation</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Subject"
                    type="text"
                    fullWidth
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />
                {availableParticipants && (
                    <Autocomplete
                        multiple
                        id="participants"
                        options={availableParticipants}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => setParticipants(value)}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" label="Participants" placeholder="Participants" />
                        )}
                    />
                )}
                {availableGroups && (
                    <Autocomplete
                        id="group-type"
                        options={availableGroups}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => setGroupType(value)}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" label="Group Type" placeholder="Group Type" />
                        )}
                    />
                )}
                <TextField
                    margin="dense"
                    label="Initial Message"
                    type="text"
                    fullWidth
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

NewConversationDialog.defaultProps = {
    availableParticipants: null,
    availableGroups: null,
    initialSubject: '',
};

export default NewConversationDialog;