import { api } from './base';
import { msgActions } from '../../websockets/websocketConstants';
import { getWebsocketManager } from './base';
let handlerRegistered = false;

const messagingApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUserConversations: build.query({
            query: () => '/messaging/user',
            providesTags: ['Messaging'],
            async onCacheEntryAdded(
                arg,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
            ) {
                let id;
                try {
                    const handleMessage = async (msg) => {
                        console.log('handling message:', msg);
                        updateCachedData((draft) => {
                            const conversation = draft.conversations.find(convo => convo.id === msg.conversation_id)
                            if (conversation) {
                                conversation.messages = [...conversation.messages, msg];
                            } else {
                                console.error(`No conversation found with ID ${msg.conversation_id}`)
                            }
                        });
                    };
                    await cacheDataLoaded;
                    const websocket = getWebsocketManager();
                    if (!handlerRegistered) {
                        id = websocket.registerHandler(
                            msgActions.ConversationMessageSent,
                            handleMessage
                        );
                        handlerRegistered = true;
                    }
                } catch {
                    console.log('Websocket Error')
                }
                await cacheEntryRemoved;
            },
        }),
        postNewMessage: build.mutation({
            query: (data) => ({
                url: '/messaging/message',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Messaging'],
        }),
        postNewConversation: build.mutation({
            query: (conversationData) => ({
                url: '/messaging/conversation',
                method: 'POST',
                body: conversationData
            }),
            invalidatesTags: ['Messaging'],
        })
    })
});

export const { useGetUserConversationsQuery, usePostNewMessageMutation, usePostNewConversationMutation } = messagingApi;

export default messagingApi;