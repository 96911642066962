import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, useTheme } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode'; // for dark theme icon
import LightModeIcon from '@mui/icons-material/LightMode'; // for light theme icon
import { toggleMode } from '../../utils/redux/slice/themeSlice'; // import the action creator

const ToggleThemeButton = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.theme.mode);

  const handleThemeToggle = () => {
    dispatch(toggleMode());
  };

  return (
    <IconButton onClick={handleThemeToggle} sx={{ color: theme.palette.primary.contrastText }}>
      {themeMode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
};

export default ToggleThemeButton;
