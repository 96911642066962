import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    notifications: [],
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addNotification: (state, action) => {
            action.payload.id = uuidv4();
            action.payload.timestamp = new Date();
            state.notifications.push(action.payload);
        },
        viewNotification: (state, action) => {
            const notification = state.notifications.find(n => n.id === action.payload);
            if (notification) {
                notification.viewed = true;
            }
        },
        clearNotifications: (state) => {
            state.notifications = [];
        },
    },
});

export const { addNotification, viewNotification, clearNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;