import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Typography, DialogActions, Button } from "@mui/material";
import NewConversationDialog from './NewConversationDialog';
import { openDialog, closeDialog, updateData } from '../../utils/redux/slice/conversationSlice'; // import the actions
import { useGetUserQuery } from '../../utils/redux/api/userApi';
import { usePostNewConversationMutation } from '../../utils/redux/api/messagingApi'; // import the mutation hook


const RecipeDialog = ({ open, handleClose, recipe, userId }) => {
    const dispatch = useDispatch();
    const { data: user, isLoading, isError } = useGetUserQuery();
    // Get the conversation state from the Redux store
    const [postConversation] = usePostNewConversationMutation();
    const conversation = useSelector((state) => state.conversation);
    const patientId = useSelector((state) => state.patient.patientId);

    if (!recipe) {
        return null;
    }

    const ingredients = JSON.parse(recipe.ingredients);
    const nutrition = typeof recipe.nutrition === 'string' ? JSON.parse(recipe.nutrition) : recipe.nutrition;

    const handleNewConversationOpen = () => {
        dispatch(openDialog({})); // dispatch the openDialog action
    };

    const displayNutrient = (nutrient, value, level = 0, parent = '') => {
        const formattedNutrient = nutrient
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        if (nutrient === 'total' && parent) {
            return null;
        }

        if (typeof value === 'string' || typeof value === 'number') {
            return (
                <Typography variant="body1" key={nutrient} style={{ marginLeft: `${level * 20}px` }}>
                    {`${formattedNutrient}: ${value}`}
                </Typography>
            );
        } else if (typeof value === 'object') {
            const total = value.total;
            return (
                <div key={nutrient}>
                    <Typography variant="body1" style={{ marginLeft: `${level * 20}px` }}>
                        {`${formattedNutrient}: ${total || ''}`}
                    </Typography>
                    {Object.entries(value).map(([key, val]) => displayNutrient(key, val, level + 1, nutrient))}
                </div>
            );
        }
    };


    const handleCreateConversation = async (conversation) => {
        try {
            // Use the mutation hook to send the data
            if (!conversation.participants.includes(user.id)) {
                // Add patient_id to the participants
                conversation.participants.push(user.id);
            }
            if (!conversation.participants.includes(patientId)) {
                // Add patient_id to the participants
                conversation.participants.push(patientId);
            }
            conversation.recipeId = recipe.id;
            console.log(conversation);
            await postConversation(conversation).unwrap();
            dispatch(closeDialog()); // dispatch the closeDialog action
        } catch (error) {
            console.error('Failed to create conversation', error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{recipe.title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    <strong>Servings:</strong> {recipe.servings}
                </Typography>
                <Typography variant="body1">
                    <strong>Ingredients:</strong>
                </Typography>
                <ul>
                    {Object.entries(ingredients).map(([key, value]) => (
                        <li key={key}>
                            {key}: {value}
                        </li>
                    ))}
                </ul>
                <Typography variant="body1">
                    <strong>Nutrition:</strong>
                </Typography>
                {Object.entries(nutrition).map(([nutrient, value]) => displayNutrient(nutrient, value))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button onClick={handleNewConversationOpen} color="primary">
                    Discuss
                </Button>
            </DialogActions>
            <NewConversationDialog
                open={conversation.open}
                onClose={() => dispatch(closeDialog())}
                onCreate={handleCreateConversation}
                initialSubject={`Recipe: ${recipe.title}`}
            />
        </Dialog>
    );
};

export default RecipeDialog;