import { useEffect, useState } from 'react';
import { Typography, TextField, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useRequestPasswordResetMutation } from '../../utils/redux/api/userApi';

const WrapperDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px', // Set a maximum width
    height: '100%',
    marginTop: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto', // Center the div
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const RequestPasswordReset = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [requestPasswordReset, { isError, error, isSuccess }] = useRequestPasswordResetMutation();

    const handleSubmit = async () => {
        try {
            await requestPasswordReset({ email });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            setMessage('Please check your email and follow the directions to reset your password');
        }
    }, [isSuccess]);

    return (
        <WrapperDiv>
            <Typography variant="h4">Reset Password</Typography>
            <Typography variant="h6">Please enter your email.</Typography>
            <TextField
                fullWidth
                placeholder='Email'
                label="Email"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
                style={{ marginTop: '1rem' }}
            />
            {message &&
                <Typography variant="subtitle1" color="error" style={{ marginTop: '1rem' }}>{message}</Typography>
            }
            <StyledButton variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </StyledButton>
        </WrapperDiv>
    );
};

export default RequestPasswordReset;