import { useState, useEffect, Fragment } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  styled,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const operators = [
  { label: "Less Than or Equal To", value: "lte" },
  { label: "Greater Than or Equal To", value: "gte" },
  { label: "Equal To", value: "eq" },
  { label: "Range", value: "range" }
];

const macronutrients = ["Carbs", "Protein", "Fat"];

const GoalContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "1rem",
});

const GoalsContainer = styled("div")({
  paddingTop: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
});

const Goals = ({ open, handleClose, goals, setGoals }) => {

  console.log('component goals: ', goals);
  const [localGoals, setLocalGoals] = useState([]);

  useEffect(() => {
    if (goals) {
      const goalsArray = Object.entries(goals).map(
        ([goal, { operator, minValue, maxValue, value }]) => ({
          goal,
          operator,
          minValue,
          maxValue,
          value
        })
      );
      setLocalGoals(goalsArray);
    } else {
      setLocalGoals([]);
    }
  }, [goals]);

  const handleAddGoal = () => {
    setLocalGoals([...localGoals, { goal: "", operator: "", minValue: "", maxValue: "", value: "" }]);
  };

  const handleGoalChange = (index, field) => (event) => {
    const newGoals = [...localGoals];
    if (field === 'goal') {
      newGoals[index][field] = event.target.value
        .replace(/\W+/g, '_') // replace non-alphanumeric characters and spaces with underscores
        .toLowerCase(); // convert to lowercase
    } else if (field === 'minValue' || field === 'maxValue' || field === 'value') {
      newGoals[index][field] = parseFloat(event.target.value);
    } else {
      newGoals[index][field] = event.target.value;
    }
    setLocalGoals(newGoals);
  };

  const handleUpdateGoals = () => {
    const updatedGoals = localGoals.reduce((acc, { goal, operator, minValue, maxValue, value }) => {
      return { ...acc, [goal]: { operator, minValue, maxValue, value } };
    }, {});
    setGoals(updatedGoals);
    handleClose();
  };

  const handleRemoveGoal = (indexToRemove) => {
    setLocalGoals(localGoals.filter((_, index) => index !== indexToRemove));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Goals</DialogTitle>
      <DialogContent>
        <GoalsContainer>
          {localGoals.map(({ goal, operator, minValue, maxValue, value }, index) => (
            <GoalContainer key={index}>
              <TextField
                label="Goal"
                value={goal}
                onChange={(event) => handleGoalChange(index, "goal")(event)}
                sx={{ flexGrow: 1 }}
              />
              <TextField
                select
                label="Operator"
                value={operator}
                onChange={(event) => handleGoalChange(index, "operator")(event)}
                fullWidth
              >
                {operators.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {operator === 'range' ? (
                <Fragment>
                  <TextField
                    label="Min Value"
                    value={minValue}
                    onChange={(event) => handleGoalChange(index, "minValue")(event)}
                    sx={{ width: "10rem" }}
                  />
                  <TextField
                    label="Max Value"
                    value={maxValue}
                    onChange={(event) => handleGoalChange(index, "maxValue")(event)}
                    sx={{ width: "10rem" }}
                  />
                </Fragment>
              ) : (
                <TextField
                  label="Value"
                  value={value}
                  onChange={(event) => handleGoalChange(index, "value")(event)}
                  sx={{ width: "10rem" }}
                />
              )}
              <Button onClick={() => handleRemoveGoal(index)}>Remove</Button>
            </GoalContainer>
          ))}
          <Button onClick={handleAddGoal}>Add Goal</Button>
        </GoalsContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleUpdateGoals}>Update Goals</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Goals;
