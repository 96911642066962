import { createTheme } from "@mui/material/styles";
import { createBreakpoints } from "@mui/system";
import { grey, green, deepOrange, orange } from "@mui/material/colors";

const breakpoints = createBreakpoints({});

const darkTheme = {
  breakpoints,
  palette: {
    mode: "dark",
    primary: {
      main: grey[400], // Neutral dark grey
      contrastText: "#000",
    },
    secondary: {
      main: green[300], // Subtle green for secondary accents
      contrastText: "#000",
    },
    error: {
      main: deepOrange[400], // Orange can be less alarming than red
      contrastText: "#fff",
    },
    warning: {
      main: grey[500], // A toned-down shade for warnings
      contrastText: "#000",
    },
    info: {
      main: grey[400], // Lighter grey for informational messages
      contrastText: "#fff",
    },
    success: {
      main: green[500], // Green for successful actions
      contrastText: "#fff",
    },
    background: {
      default: grey[900], // Very dark grey for the main background
      paper: grey[600], // Slightly lighter grey for paper elements
    },
    text: {
      primary: "#fff",
      secondary: grey[500],
    },
    divider: grey[700], // Divider color that fits the dark theme
    optional: {
      paper: grey[800], // Optional paper elements
      background: grey[700], // Optional background for less prominent components
      link: green[500], // Color for links
      main: grey[600], // Main color for optional elements
    },
    scrollbar: {
      thumb: '#d3d3d3',
      track: '#757575',
    },
    chat: {
      userMessage: '#0a3d62', // dark blue for user messages
      otherMessage: '#bdbdbd', // dark gray for other messages
      userMessageText: '#d3d3d3', // light gray text for user messages
      otherMessageText: '#ffffff', // white text for other messages
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "3rem",
      lineHeight: 1.167,
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "2.5rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
      [breakpoints.down("xs")]: {
        fontSize: "1.5rem",
      },
    },
    h2: {
      fontSize: "2rem",
      lineHeight: 1.2,
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "1.75rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
      [breakpoints.down("xs")]: {
        fontSize: "1.25rem",
      },
    },
    // You can continue to define other typography settings (h3, h4, body1, etc.) here...
  },
  components: {
    MuiTable: {
      styleOverrides: {
        header: {
          backgroundColor: grey[800], // or any color you prefer
        },
      },
    },
  },
  // You can also define custom mixins, shadows, transitions, etc., if needed
};

const lightTheme = {
  breakpoints,
  palette: {
    mode: "light",
    primary: {
      main: grey[500], // A lighter shade of grey for primary elements
      contrastText: "#000",
    },
    secondary: {
      main: green[500], // A more vibrant green for secondary accents
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    error: {
      main: orange[500], // Orange for errors, suitable for light background
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    warning: {
      main: orange[300], // Lighter orange for warnings
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: grey[500], // Mid-tone grey for informational messages
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: green[700], // Darker green for successful actions
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    background: {
      default: grey[100], // Very light grey for the main background
      paper: grey[50], // Even lighter grey for paper elements
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)", // Black with high opacity
      secondary: "rgba(0, 0, 0, 0.54)", // Black with medium opacity
    },
    divider: grey[300], // Light grey for dividers
    optional: {
      paper: grey[2570],
      background: grey[200], // Light background for optional components
      link: green[700], // Darker green for links to stand out
      main: grey[400], // Neutral grey for optional elements
    },
    scrollbar: {
      thumb: '#757575',
      track: '#d3d3d3',
    },
    chat: {
      userMessage: '#a4c0f4', // light blue for user messages
      otherMessage: '#d3d3d3', // light gray for other messages
      userMessageText: '#333333', // dark gray text for user messages
      otherMessageText: '#000000', // black text for other messages
    },
  },
  // The typography and other settings remain the same as the dark theme
  typography: darkTheme.typography,
  components: {
    MuiTable: {
      styleOverrides: {
        header: {
          backgroundColor: grey[300], // or any color you prefer
        },
      },
    },
  },
  // Add any custom mixins, shadows, transitions, etc., here if needed
};


export { darkTheme, lightTheme }
