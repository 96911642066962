export class LinearWebsocketRetryPolicy {
    constructor(maxRetries, delayIncrementMs) {
        this.retries = 1;
        this.maxRetries = maxRetries;
        this.delayIncrementMs = delayIncrementMs || 5000;
    }

    getRetries = () => this.retries;

    exceededMax = () =>
        this.maxRetries === undefined ? false : this.retries > this.maxRetries;

    wait = () => {
        const delay = this.retries * this.delayIncrementMs;
        this.retries += 1;
        return delay;
    };

    reset = () => {
        this.retries = 1;
    };
}