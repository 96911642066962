import { useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, styled, Typography } from '@mui/material';
import { useGetProgramPatientsDetailQuery } from '../../utils/redux/api/programApi';
import { useGetUserQuery } from '../../utils/redux/api/userApi';
import { useGetProgramGroupsQuery, useCreatePatientInviteMutation } from '../../utils/redux/api/programApi';
import { useNavigate } from 'react-router-dom';
import InvitePatientDialog from './InvitePatientForm';




const StyledDiv = styled('div')(({ theme }) => ({
    height: '100%',
    margin: theme.spacing(2),
    width: '100%',
}));

const StyledButtonDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(4),
}));

const ProgramUsers = () => {
    const navigate = useNavigate();
    const [createPatientInvite, { isLoading: isCreatingInvite }] = useCreatePatientInviteMutation();

    const { data: user } = useGetUserQuery();
    const {
        data: programGroups,
        isLoading: isProgramGroupsLoading,
        isError: isProgramGroupsError,
        error: programGroupsError,
    } = useGetProgramGroupsQuery(user?.programUser?.programId, { skip: !user });
    const {
        data: programPatientsDetail,
        isLoading: isProgramPatientsDetailLoading,
        isError: isProgramPatientsDetailError,
        error: programPatientsDetailError,
    } = useGetProgramPatientsDetailQuery();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInvite = async (patientEmail, programPatientGroupId) => {
        try {
            const response = await createPatientInvite({ patientEmail, programPatientGroupId });
            console.log(`Invitation created: ${response.data}`);
            setOpen(false);
        } catch (error) {
            console.error('Failed to create invitation:', error);
        }
    };

    const rows = useMemo(() => {
        if (programPatientsDetail?.patients && programGroups?.groups) {
            return programPatientsDetail.patients.map((patient, index) => {
                const group = programGroups.groups.find((group) => group.id === patient.patientDetail.programGroupId);

                const patientGoalsString = patient.goals
                    ? Object.entries(patient.goals).map(([key, goal]) => `${key}: ${goal.operator} ${goal.value}`).join(', ')
                    : 'N/A';

                const groupGoalsString = group && group.goals
                    ? Object.entries(group.goals).map(([key, goal]) => `${key}: ${goal.operator} ${goal.value}`).join(', ')
                    : 'N/A';

                return {
                    id: index, // add a unique id for each row
                    patientId: patient.patientId,
                    email: patient.email,
                    firstName: patient.firstName,
                    lastName: patient.lastName,
                    profilePictureUrl: patient.profilePictureUrl,
                    profilePictureExpiresAt: patient.profilePictureExpiresAt,
                    displayName: patient.displayName,
                    goals: patientGoalsString,
                    originalGoals: group.goals, // store the original goals
                    dietRestrictions: patient.dietRestrictions,
                    groupName: group ? group.restrictionType : 'N/A',
                    groupGoals: groupGoalsString,

                    groupDietRestrictions: group ? group.dietRestrictions : 'N/A',
                };
            });
        } else {
            return [];
        }
    }, [programGroups, programPatientsDetail]);
    const columns = [
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'goals', headerName: 'Goals', width: 200 },
        { field: 'dietRestrictions', headerName: 'Diet Restrictions', width: 200 },
        { field: 'groupName', headerName: 'Group Name', width: 200 },
        { field: 'groupGoals', headerName: 'Group Goals', width: 200 },
        { field: 'groupDietRestrictions', headerName: 'Group Diet Restrictions', width: 200 },
        {
            field: 'profile',
            headerName: 'Profile',
            renderCell: (params) => (
                <Button variant="contained" color="primary" onClick={() => navigate(`/program/patient/${params.row.patientId}`, { state: { patient: params.row, goals: params.row.originalGoals, dietRestrictions: params.row.groupDietRestrictions } })}>
                    View Profile
                </Button>
            ),
            width: 150,
        },
    ];

    if (isProgramGroupsLoading || isProgramPatientsDetailLoading) {
        return <div>Loading...</div>; // render loading state
    }

    if (isProgramGroupsError || isProgramPatientsDetailError) {
        return (<div>Error: {programGroupsError?.message || programPatientsDetailError?.message}</div>); // render error state
    }

    return (
        <StyledDiv>
            <Typography variant="h4" component="h1" gutterBottom>Patient List</Typography>
            <StyledButtonDiv>
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Invite New Patient
                </Button>
            </StyledButtonDiv>
            <InvitePatientDialog
                groups={programGroups.groups}
                open={open}
                handleClose={handleClose}
                handleInvite={handleInvite}
            />
            <DataGrid rows={rows} columns={columns} pageSize={5} getRowId={(row) => row.id} />
        </StyledDiv>
    );
}

export default ProgramUsers;