import { api } from './base';

const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query({
            query: () => ({
                url: '/users/me/program',
                credentials: 'include',
            }),
            providesTags: ['User'],

        }),
        postLogin: build.mutation({
            query: (data) => {
                const details = {
                    ...data,
                    grant_type: 'password',
                };
                const formBody = Object.keys(details)
                    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]))
                    .join('&');

                return {
                    url: '/auth/db/login',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    },
                    body: formBody,
                    credentials: 'include'
                };
            }
        }),
        postLogout: build.mutation({
            query: () => ({
                url: '/auth/db/logout',
                method: 'POST',
                credentials: 'include',
            }),
            invalidatesTags: ['User']
        }),
        patchUser: build.mutation({
            query: (data) => ({
                url: '/users/me',
                method: 'PATCH',
                body: data,
                credentials: 'include',
            }),
            invalidatesTags: ['User'],
        }),
        postPicture: build.mutation({
            query: (formData) => ({
                url: '/profile/upload',
                method: 'POST',
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                credentials: 'include',
            }),
            invalidatesTags: ['User', 'Recipe', 'Staff', 'Patients']
        }),
        postRegister: build.mutation({
            query: (data) => ({
                url: '/auth/register',
                method: 'POST',
                body: data,
            }),
        }),
        requestVerifyToken: build.mutation({
            query: (data) => ({
                url: '/auth/request-verify-token',
                method: 'POST',
                body: data,
            }),
        }),
        verifyToken: build.mutation({
            query: (data) => ({
                url: '/auth/verify',
                method: 'POST',
                body: data,
            }),
        }),
        requestPasswordReset: build.mutation({
            query: (data) => ({
                url: '/auth/forgot-password',
                method: 'POST',
                body: data,
            }),
        }),
        resetPassword: build.mutation({
            query: (data) => ({
                url: '/auth/reset-password',
                method: 'POST',
                body: data,
            }),
        }),
        deleteUser: build.mutation({
            query: (data) => ({
                url: '/user-delete',
                method: 'DELETE',
                body: data,
                credentials: 'include',
            }),
            invalidatesTags: ['User'],
        }),
        refreshSession: build.mutation({
            query: () => ({
                url: '/auth/refresh-session',
                method: 'POST',
                credentials: 'include',
            }),
        }),
    })
});

export const {
    useRefreshSessionMutation,
    useGetUserQuery,
    usePostLoginMutation,
    usePostLogoutMutation,
    usePatchUserMutation,
    usePostPictureMutation,
    usePostRegisterMutation,
    useRequestVerifyTokenMutation,
    useVerifyTokenMutation,
    useRequestPasswordResetMutation,
    useResetPasswordMutation,
    useDeleteUserMutation
} = userApi;

export default userApi;
