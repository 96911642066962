import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { WebsocketManager } from '../../websockets/WebsocketManager';

let websocket = null;

export function initializeWebsocket() {
    if (!websocket) {
        websocket = new WebsocketManager({
            onWSState: (state, event) => {
                console.log(`FoodFresh Websocket:`, state, event || '');
            },
        });
    }
}

export function getWebsocketManager() {
    return websocket;
}
const baseUrl = process.env.REACT_APP_API_URL // || 'https://192.168.1.175:8000/api'
console.log('base url: ', baseUrl)
export const api = createApi({
    tagTypes: ['User', 'Recipe', 'Staff', 'Patients', 'Messaging', 'MealPlan'],
    baseQuery: fetchBaseQuery({
        baseUrl,
        credentials: 'include',
        prepareHeaders(headers) {
            return headers;
        },
    }),
    endpoints: () => ({}),
});