// Define your message actions as an object
export const msgActions = Object.freeze({
    ConversationMessageSent: 'MESSAGE_ADDED',
    // ... other actions
});

// Function to get a new message callback map
export const getMsgCallbackMap = () => {
    const newMap = {};
    Object.keys(msgActions).forEach((key) => {
        newMap[key] = [];
    });
    return newMap;
};